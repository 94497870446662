export default {
  SELECT_CHAIN: 'SELECT_CHAIN',
  SELECT_CONTACT: 'SELECT_CONTACT',

  GET_CHAINS_REQUEST: 'GET_CHAINS_REQUEST',
  GET_CHAINS_SUCCESS: 'GET_CHAINS_SUCCESS',
  GET_CHAINS_FAILURE: 'GET_CHAINS_FAILURE',

  GET_CHAIN_DETAIL_REQUEST: 'GET_CHAIN_DETAIL_REQUEST',
  GET_CHAIN_DETAIL_SUCCESS: 'GET_CHAIN_DETAIL_SUCCESS',
  GET_CHAIN_DETAIL_FAILURE: 'GET_CHAIN_DETAIL_FAILURE',

  CREATE_CHAIN_REQUEST: 'CREATE_CHAIN_REQUEST',
  CREATE_CHAIN_SUCCESS: 'CREATE_CHAIN_SUCCESS',
  CREATE_CHAIN_FAILURE: 'CREATE_CHAIN_FAILURE',

  UPDATE_CHAIN_REQUEST: 'UPDATE_CHAIN_REQUEST',
  UPDATE_CHAIN_SUCCESS: 'UPDATE_CHAIN_SUCCESS',
  UPDATE_CHAIN_FAILURE: 'UPDATE_CHAIN_FAILURE',

  DELETE_CHAIN_REQUEST: 'DELETE_CHAIN_REQUEST',
  DELETE_CHAIN_SUCCESS: 'DELETE_CHAIN_SUCCESS',
  DELETE_CHAIN_FAILURE: 'DELETE_CHAIN_FAILURE',

  ADD_CONTACT_REQUEST: 'ADD_CONTACT_REQUEST',
  ADD_CONTACT_SUCCESS: 'ADD_CONTACT_SUCCESS',
  ADD_CONTACT_FAILURE: 'ADD_CONTACT_FAILURE',

  ADD_SUBJECTS_REQUEST: 'ADD_SUBJECTS_REQUEST',
  ADD_SUBJECTS_SUCCESS: 'ADD_SUBJECTS_SUCCESS',
  ADD_SUBJECTS_FAILURE: 'ADD_SUBJECTS_FAILURE',

  GET_CONTACTS_REQUEST: 'GET_CONTACTS_REQUEST',
  GET_CONTACTS_SUCCESS: 'GET_CONTACTS_SUCCESS',
  GET_CONTACTS_FAILURE: 'GET_CONTACTS_FAILURE',

  GET_CONTACTS_BY_CHAIN_REQUEST: 'GET_CONTACTS_BY_CHAIN_REQUEST',
  GET_CONTACTS_BY_CHAIN_SUCCESS: 'GET_CONTACTS_BY_CHAIN_SUCCESS',
  GET_CONTACTS_BY_CHAIN_FAILURE: 'GET_CONTACTS_BY_CHAIN_FAILURE',

  CREATE_CONTACT_ON_CHAIN_REQUEST: 'CREATE_CONTACT_ON_CHAIN_REQUEST',
  CREATE_CONTACT_ON_CHAIN_SUCCESS: 'CREATE_CONTACT_ON_CHAIN_SUCCESS',
  CREATE_CONTACT_ON_CHAIN_FAILURE: 'CREATE_CONTACT_ON_CHAIN_FAILURE',

  GET_CONTACT_LOCATIONS_REQUEST: 'GET_CONTACT_LOCATIONS_REQUEST',
  GET_CONTACT_LOCATIONS_SUCCESS: 'GET_CONTACT_LOCATIONS_SUCCESS',
  GET_CONTACT_LOCATIONS_FAILURE: 'GET_CONTACT_LOCATIONS_FAILURE',

  GET_CRITERIAS_REQUEST: 'GET_CRITERIAS_REQUEST',
  GET_CRITERIAS_SUCCESS: 'GET_CRITERIAS_SUCCESS',
  GET_CRITERIAS_FAILURE: 'GET_CRITERIAS_FAILURE',

  GET_ASKING_REQUEST: 'GET_ASKING_REQUEST',
  GET_ASKING_SUCCESS: 'GET_ASKING_SUCCESS',
  GET_ASKING_FAILURE: 'GET_ASKING_FAILURE',

  UPDATE_ASKING_REQUEST: 'UPDATE_ASKING_REQUEST',
  UPDATE_ASKING_SUCCESS: 'UPDATE_ASKING_SUCCESS',
  UPDATE_ASKING_FAILURE: 'UPDATE_ASKING_FAILURE',

  CONCLUDE_ASKING_REQUEST: 'CONCLUDE_ASKING_REQUEST',
  CONCLUDE_ASKING_SUCCESS: 'CONCLUDE_ASKING_SUCCESS',
  CONCLUDE_ASKING_FAILURE: 'CONCLUDE_ASKING_FAILURE',

  CREATE_CONTACT_LOCATION_REQUEST: 'CREATE_CONTACT_LOCATION_REQUEST',
  CREATE_CONTACT_LOCATION_SUCCESS: 'CREATE_CONTACT_LOCATION_SUCCESS',
  CREATE_CONTACT_LOCATION_FAILURE: 'CREATE_CONTACT_LOCATION_FAILURE',

  UPDATE_CONTACT_LOCATION_REQUEST: 'UPDATE_CONTACT_LOCATION_REQUEST',
  UPDATE_CONTACT_LOCATION_SUCCESS: 'UPDATE_CONTACT_LOCATION_SUCCESS',
  UPDATE_CONTACT_LOCATION_FAILURE: 'UPDATE_CONTACT_LOCATION_FAILURE',

  DELETE_CONTACT_LOCATION_REQUEST: 'DELETE_CONTACT_LOCATION_REQUEST',
  DELETE_CONTACT_LOCATION_SUCCESS: 'DELETE_CONTACT_LOCATION_SUCCESS',
  DELETE_CONTACT_LOCATION_FAILURE: 'DELETE_CONTACT_LOCATION_FAILURE',

  GET_CONTACT_VEHICLES_REQUEST: 'GET_CONTACT_VEHICLES_REQUEST',
  GET_CONTACT_VEHICLES_SUCCESS: 'GET_CONTACT_VEHICLES_SUCCESS',
  GET_CONTACT_VEHICLES_FAILURE: 'GET_CONTACT_VEHICLES_FAILURE',

  CREATE_CONTACT_VEHICLE_REQUEST: 'CREATE_CONTACT_VEHICLE_REQUEST',
  CREATE_CONTACT_VEHICLE_SUCCESS: 'CREATE_CONTACT_VEHICLE_SUCCESS',
  CREATE_CONTACT_VEHICLE_FAILURE: 'CREATE_CONTACT_VEHICLE_FAILURE',

  UPDATE_CONTACT_VEHICLE_REQUEST: 'UPDATE_CONTACT_VEHICLE_REQUEST',
  UPDATE_CONTACT_VEHICLE_SUCCESS: 'UPDATE_CONTACT_VEHICLE_SUCCESS',
  UPDATE_CONTACT_VEHICLE_FAILURE: 'UPDATE_CONTACT_VEHICLE_FAILURE',

  DELETE_CONTACT_VEHICLE_REQUEST: 'DELETE_CONTACT_VEHICLE_REQUEST',
  DELETE_CONTACT_VEHICLE_SUCCESS: 'DELETE_CONTACT_VEHICLE_SUCCESS',
  DELETE_CONTACT_VEHICLE_FAILURE: 'DELETE_CONTACT_VEHICLE_FAILURE',

  GET_ASKING_RESULT_REQUEST: 'GET_ASKING_RESULT_REQUEST',
  GET_ASKING_RESULT_SUCCESS: 'GET_ASKING_RESULT_SUCCESS',
  GET_ASKING_RESULT_FAILURE: 'GET_ASKING_RESULT_FAILURE',

  CLEAR_ASKING: 'CLEAR_ASKING',

  GET_SUBJECT_DETAIL_REQUEST: 'GET_SUBJECT_DETAIL_REQUEST',
  GET_SUBJECT_DETAIL_SUCCESS: 'GET_SUBJECT_DETAIL_SUCCESS',
  GET_SUBJECT_DETAIL_FAILURE: 'GET_SUBJECT_DETAIL_FAILURE',

  GET_CONTACTS_BY_SUBJECT_REQUEST: 'GET_CONTACTS_BY_SUBJECT_REQUEST',
  GET_CONTACTS_BY_SUBJECT_SUCCESS: 'GET_CONTACTS_BY_SUBJECT_SUCCESS',
  GET_CONTACTS_BY_SUBJECT_FAILURE: 'GET_CONTACTS_BY_SUBJECT_FAILURE',

  UPDATE_INVESTIGATION_REQUEST: 'UPDATE_INVESTIGATION_REQUEST',
  UPDATE_INVESTIGATION_SUCCESS: 'UPDATE_INVESTIGATION_SUCCESS',
  UPDATE_INVESTIGATION_FAILURE: 'UPDATE_INVESTIGATION_FAILURE',

  GET_CHAIN_MAP_BY_ID_REQUEST: 'GET_CHAIN_MAP_BY_ID_REQUEST',
  GET_CHAIN_MAP_BY_ID_SUCCESS: 'GET_CHAIN_MAP_BY_ID_SUCCESS',
  GET_CHAIN_MAP_BY_ID_FAILURE: 'GET_CHAIN_MAP_BY_ID_FAILURE',

  GET_CHAIN_MAP_REQUEST: 'GET_CHAIN_MAP_REQUEST',
  GET_CHAIN_MAP_SUCCESS: 'GET_CHAIN_MAP_SUCCESS',
  GET_CHAIN_MAP_FAILURE: 'GET_CHAIN_MAP_FAILURE',

  CHECK_POSITIVE_REQUEST: 'CHECK_POSITIVE_REQUEST',
  CHECK_POSITIVE_SUCCESS: 'CHECK_POSITIVE_SUCCESS',
  CHECK_POSITIVE_FAILURE: 'CHECK_POSITIVE_FAILURE',

  CONCLUDE_CONTACT_REQUEST: 'CONCLUDE_CONTACT_REQUEST',
  CONCLUDE_CONTACT_SUCCESS: 'CONCLUDE_CONTACT_SUCCESS',
  CONCLUDE_CONTACT_FAILURE: 'CONCLUDE_CONTACT_FAILURE',

  GET_CHAIN_MAPS_REQUEST: 'GET_CHAIN_MAPS_REQUEST',
  GET_CHAIN_MAPS_SUCCESS: 'GET_CHAIN_MAPS_SUCCESS',
  GET_CHAIN_MAPS_FAILURE: 'GET_CHAIN_MAPS_FAILURE',

  GET_CHAIN_MAP_DETAIL_REQUEST: 'GET_CHAIN_MAP_DETAIL_REQUEST',
  GET_CHAIN_MAP_DETAIL_SUCCESS: 'GET_CHAIN_MAP_DETAIL_SUCCESS',
  GET_CHAIN_MAP_DETAIL_FAILURE: 'GET_CHAIN_MAP_DETAIL_FAILURE',

  GET_CHAIN_MAPS_WITH_LOCATIONS_REQUEST:
    'GET_CHAIN_MAPS_WITH_LOCATIONS_REQUEST',
  GET_CHAIN_MAPS_WITH_LOCATIONS_SUCCESS:
    'GET_CHAIN_MAPS_WITH_LOCATIONS_SUCCESS',
  GET_CHAIN_MAPS_WITH_LOCATIONS_FAILURE:
    'GET_CHAIN_MAPS_WITH_LOCATIONS_FAILURE',

  GET_CHAIN_MAP_DETAIL_WITH_LOCATIONS_REQUEST:
    'GET_CHAIN_MAP_DETAIL_WITH_LOCATIONS_REQUEST',
  GET_CHAIN_MAP_DETAIL_WITH_LOCATIONS_SUCCESS:
    'GET_CHAIN_MAP_DETAIL_WITH_LOCATIONS_SUCCESS',
  GET_CHAIN_MAP_DETAIL_WITH_LOCATIONS_FAILURE:
    'GET_CHAIN_MAP_DETAIL_WITH_LOCATIONS_FAILURE',

  DELETE_CONTACT_REQUEST: 'DELETE_CONTACT_REQUEST',
  DELETE_CONTACT_SUCCESS: 'DELETE_CONTACT_SUCCESS',
  DELETE_CONTACT_FAILURE: 'DELETE_CONTACT_FAILURE',

  GET_CHAIN_SUBJECTS_REQUEST: 'GET_CHAIN_SUBJECTS_REQUEST',
  GET_CHAIN_SUBJECTS_SUCCESS: 'GET_CHAIN_SUBJECTS_SUCCESS',
  GET_CHAIN_SUBJECTS_FAILURE: 'GET_CHAIN_SUBJECTS_FAILURE',

  GET_LOCATION_VISITORS_REQUEST: 'GET_LOCATION_VISITORS_REQUEST',
  GET_LOCATION_VISITORS_SUCCESS: 'GET_LOCATION_VISITORS_SUCCESS',
  GET_LOCATION_VISITORS_FAILURE: 'GET_LOCATION_VISITORS_FAILURE',

  EXPORT_CONTACTS_BY_CHAIN_REQUEST: 'EXPORT_CONTACTS_BY_CHAIN_REQUEST',
  EXPORT_CONTACTS_BY_CHAIN_SUCCESS: 'EXPORT_CONTACTS_BY_CHAIN_SUCCESS',
  EXPORT_CONTACTS_BY_CHAIN_FAILURE: 'EXPORT_CONTACTS_BY_CHAIN_FAILURE',
};
