import React from 'react';

const QuarantineRequestFilter = (props) => {
  return (
    <div>
      QuarantineRequestFilter
    </div>
  );
};

export default QuarantineRequestFilter;
