export default {
  SELECT_GENERAL: 'SELECT_GENERAL',

  GET_DISEASE_TYPES_REQUEST: 'GET_DISEASE_TYPES_REQUEST',
  GET_DISEASE_TYPES_SUCCESS: 'GET_DISEASE_TYPES_SUCCESS',
  GET_DISEASE_TYPES_FAILURE: 'GET_DISEASE_TYPES_FAILURE',

  UPDATE_DISEASE_TYPE_REQUEST: 'UPDATE_DISEASE_TYPE_REQUEST',
  UPDATE_DISEASE_TYPE_SUCCESS: 'UPDATE_DISEASE_TYPE_SUCCESS',
  UPDATE_DISEASE_TYPE_FAILURE: 'UPDATE_DISEASE_TYPE_FAILURE',

  CREATE_DISEASE_TYPE_REQUEST: 'CREATE_DISEASE_TYPE_REQUEST',
  CREATE_DISEASE_TYPE_SUCCESS: 'CREATE_DISEASE_TYPE_SUCCESS',
  CREATE_DISEASE_TYPE_FAILURE: 'CREATE_DISEASE_TYPE_FAILURE',

  DELETE_DISEASE_TYPE_REQUEST: 'DELETE_DISEASE_TYPE_REQUEST',
  DELETE_DISEASE_TYPE_SUCCESS: 'DELETE_DISEASE_TYPE_SUCCESS',
  DELETE_DISEASE_TYPE_FAILURE: 'DELETE_DISEASE_TYPE_FAILURE',

  GET_INFECTION_TYPES_REQUEST: 'GET_INFECTION_TYPES_REQUEST',
  GET_INFECTION_TYPES_SUCCESS: 'GET_INFECTION_TYPES_SUCCESS',
  GET_INFECTION_TYPES_FAILURE: 'GET_INFECTION_TYPES_FAILURE',

  UPDATE_INFECTION_TYPE_REQUEST: 'UPDATE_INFECTION_TYPE_REQUEST',
  UPDATE_INFECTION_TYPE_SUCCESS: 'UPDATE_INFECTION_TYPE_SUCCESS',
  UPDATE_INFECTION_TYPE_FAILURE: 'UPDATE_INFECTION_TYPE_FAILURE',

  CREATE_INFECTION_TYPE_REQUEST: 'CREATE_INFECTION_TYPE_REQUEST',
  CREATE_INFECTION_TYPE_SUCCESS: 'CREATE_INFECTION_TYPE_SUCCESS',
  CREATE_INFECTION_TYPE_FAILURE: 'CREATE_INFECTION_TYPE_FAILURE',

  DELETE_INFECTION_TYPE_REQUEST: 'DELETE_INFECTION_TYPE_REQUEST',
  DELETE_INFECTION_TYPE_SUCCESS: 'DELETE_INFECTION_TYPE_SUCCESS',
  DELETE_INFECTION_TYPE_FAILURE: 'DELETE_INFECTION_TYPE_FAILURE',

  GET_INVESTIGATION_CRITERIAS_REQUEST: 'GET_INVESTIGATION_CRITERIAS_REQUEST',
  GET_INVESTIGATION_CRITERIAS_SUCCESS: 'GET_INVESTIGATION_CRITERIAS_SUCCESS',
  GET_INVESTIGATION_CRITERIAS_FAILURE: 'GET_INVESTIGATION_CRITERIAS_FAILURE',

  CREATE_CRITERIA_REQUEST: 'CREATE_CRITERIA_REQUEST',
  CREATE_CRITERIA_SUCCESS: 'CREATE_CRITERIA_SUCCESS',
  CREATE_CRITERIA_FAILURE: 'CREATE_CRITERIA_FAILURE',

  UPDATE_CRITERIA_REQUEST: 'UPDATE_CRITERIA_REQUEST',
  UPDATE_CRITERIA_SUCCESS: 'UPDATE_CRITERIA_SUCCESS',
  UPDATE_CRITERIA_FAILURE: 'UPDATE_CRITERIA_FAILURE',

  DELETE_CRITERIA_REQUEST: 'DELETE_CRITERIA_REQUEST',
  DELETE_CRITERIA_SUCCESS: 'DELETE_CRITERIA_SUCCESS',
  DELETE_CRITERIA_FAILURE: 'DELETE_CRITERIA_FAILURE',
};
