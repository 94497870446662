export default {
  GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

  GET_PROFILES_REQUEST: 'GET_PROFILES_REQUEST',
  GET_PROFILES_SUCCESS: 'GET_PROFILES_SUCCESS',
  GET_PROFILES_FAILURE: 'GET_PROFILES_FAILURE',

  CREATE_PROFILE_REQUEST: 'CREATE_PROFILE_REQUEST',
  CREATE_PROFILE_SUCCESS: 'CREATE_PROFILE_SUCCESS',
  CREATE_PROFILE_FAILURE: 'CREATE_PROFILE_FAILURE',

  UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

  DELETE_PROFILE_REQUEST: 'DELETE_PROFILE_REQUEST',
  DELETE_PROFILE_SUCCESS: 'DELETE_PROFILE_SUCCESS',
  DELETE_PROFILE_FAILURE: 'DELETE_PROFILE_FAILURE',

  VERIFY_PROFILE_REQUEST: 'VERIFY_PROFILE_REQUEST',
  VERIFY_PROFILE_SUCCESS: 'VERIFY_PROFILE_SUCCESS',
  VERIFY_PROFILE_FAILURE: 'VERIFY_PROFILE_FAILURE',

  GET_RELATED_REQUEST: 'GET_RELATED_REQUEST',
  GET_RELATED_SUCCESS: 'GET_RELATED_SUCCESS',
  GET_RELATED_FAILURE: 'GET_RELATED_FAILURE',

  GET_CHILD_PROFILE_REQUEST: 'GET_CHILD_PROFILE_REQUEST',
  GET_CHILD_PROFILE_SUCCESS: 'GET_CHILD_PROFILE_SUCCESS',
  GET_CHILD_PROFILE_FAILURE: 'GET_CHILD_PROFILE_FAILURE',

  RESET_RELATED: 'RESET_RELATED',

  GET_DUPLICATE_PROFILE_REQUEST: 'GET_DUPLICATE_PROFILE_REQUEST',
  GET_DUPLICATE_PROFILE_SUCCESS: 'GET_DUPLICATE_PROFILE_SUCCESS',
  GET_DUPLICATE_PROFILE_FAILURE: 'GET_DUPLICATE_PROFILE_FAILURE',

  CREATE_IMMUNIZATION_FOR_PROFILE_REQUEST: 'CREATE_IMMUNIZATION_FOR_PROFILE_REQUEST',
  CREATE_IMMUNIZATION_FOR_PROFILE_SUCCESS: 'CREATE_IMMUNIZATION_FOR_PROFILE_SUCCESS',
  CREATE_IMMUNIZATION_FOR_PROFILE_FAILURE: 'CREATE_IMMUNIZATION_FOR_PROFILE_FAILURE',

  UPDATE_IMMUNIZATION_FOR_PROFILE_REQUEST: 'UPDATE_IMMUNIZATION_FOR_PROFILE_REQUEST',
  UPDATE_IMMUNIZATION_FOR_PROFILE_SUCCESS: 'UPDATE_IMMUNIZATION_FOR_PROFILE_SUCCESS',
  UPDATE_IMMUNIZATION_FOR_PROFILE_FAILURE: 'UPDATE_IMMUNIZATION_FOR_PROFILE_FAILURE',

  GET_SYMPTOMS_REQUEST: 'GET_SYMPTOMS_REQUEST',
  GET_SYMPTOMS_SUCCESS: 'GET_SYMPTOMS_SUCCESS',
  GET_SYMPTOMS_FAILURE: 'GET_SYMPTOMS_FAILURE',

  GET_UNDERLYING_DISEASES_REQUEST: 'GET_UNDERLYING_DISEASES_REQUEST',
  GET_UNDERLYING_DISEASES_SUCCESS: 'GET_UNDERLYING_DISEASES_SUCCESS',
  GET_UNDERLYING_DISEASES_FAILURE: 'GET_UNDERLYING_DISEASES_FAILURE',

  CREATE_PROFILE_WITH_IMMUNIZATION_REQUEST: 'CREATE_PROFILE_WITH_IMMUNIZATION_REQUEST',
  CREATE_PROFILE_WITH_IMMUNIZATION_SUCCESS: 'CREATE_PROFILE_WITH_IMMUNIZATION_SUCCESS',
  CREATE_PROFILE_WITH_IMMUNIZATION_FAILURE: 'CREATE_PROFILE_WITH_IMMUNIZATION_FAILURE',

  UPDATE_PROFILE_WITH_IMMUNIZATION_REQUEST: 'UPDATE_PROFILE_WITH_IMMUNIZATION_REQUEST',
  UPDATE_PROFILE_WITH_IMMUNIZATION_SUCCESS: 'UPDATE_PROFILE_WITH_IMMUNIZATION_SUCCESS',
  UPDATE_PROFILE_WITH_IMMUNIZATION_FAILURE: 'UPDATE_PROFILE_WITH_IMMUNIZATION_FAILURE',

  GET_INFECTIOUS_DISEASE_HISTORIES_REQUEST: 'GET_INFECTIOUS_DISEASE_HISTORIES_REQUEST',
  GET_INFECTIOUS_DISEASE_HISTORIES_SUCCESS: 'GET_INFECTIOUS_DISEASE_HISTORIES_SUCCESS',
  GET_INFECTIOUS_DISEASE_HISTORIES_FAILURE: 'GET_INFECTIOUS_DISEASE_HISTORIES_FAILURE',

  GET_INFECTIOUS_DISEASE_HISTORIES_BY_PROFILE_REQUEST: 'GET_INFECTIOUS_DISEASE_HISTORIES_BY_PROFILE_REQUEST',
  GET_INFECTIOUS_DISEASE_HISTORIES_BY_PROFILE_SUCCESS: 'GET_INFECTIOUS_DISEASE_HISTORIES_BY_PROFILE_SUCCESS',
  GET_INFECTIOUS_DISEASE_HISTORIES_BY_PROFILE_FAILURE: 'GET_INFECTIOUS_DISEASE_HISTORIES_BY_PROFILE_FAILURE',

  CHECK_POSITIVE_REQUEST: 'CHECK_POSITIVE_REQUEST',
  CHECK_POSITIVE_SUCCESS: 'CHECK_POSITIVE_SUCCESS',
  CHECK_POSITIVE_FAILURE: 'CHECK_POSITIVE_FAILURE',

  GET_INFECTIOUS_DISEASES_REQUEST: 'GET_INFECTIOUS_DISEASES_REQUEST',
  GET_INFECTIOUS_DISEASES_SUCCESS: 'GET_INFECTIOUS_DISEASES_SUCCESS',
  GET_INFECTIOUS_DISEASES_FAILURE: 'GET_INFECTIOUS_DISEASES_FAILURE',

};
