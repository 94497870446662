import ChainTable from 'chain/components/ChainTable';
import React from 'react';

const ChainPage = () => (
  <div>
    <ChainTable />
  </div>
);

export default ChainPage;
