export default {
  GET_COLLECTING_SESSION_DETAIL_REQUEST:
    'GET_COLLECTING_SESSION_DETAIL_REQUEST',
  GET_COLLECTING_SESSION_DETAIL_SUCCESS:
    'GET_COLLECTING_SESSION_DETAIL_SUCCESS',
  GET_COLLECTING_SESSION_DETAIL_FAILURE:
    'GET_COLLECTING_SESSION_DETAIL_FAILURE',

  GET_COLLECTING_SESSIONS_REQUEST: 'GET_COLLECTING_SESSIONS_REQUEST',
  GET_COLLECTING_SESSIONS_SUCCESS: 'GET_COLLECTING_SESSIONS_SUCCESS',
  GET_COLLECTING_SESSIONS_FAILURE: 'GET_COLLECTING_SESSIONS_FAILURE',

  CREATE_COLLECTING_SESSION_REQUEST: 'CREATE_COLLECTING_SESSION_REQUEST',
  CREATE_COLLECTING_SESSION_SUCCESS: 'CREATE_COLLECTING_SESSION_SUCCESS',
  CREATE_COLLECTING_SESSION_FAILURE: 'CREATE_COLLECTING_SESSION_FAILURE',

  UPDATE_COLLECTING_SESSION_REQUEST: 'UPDATE_COLLECTING_SESSION_REQUEST',
  UPDATE_COLLECTING_SESSION_SUCCESS: 'UPDATE_COLLECTING_SESSION_SUCCESS',
  UPDATE_COLLECTING_SESSION_FAILURE: 'UPDATE_COLLECTING_SESSION_FAILURE',

  DELETE_COLLECTING_SESSION_REQUEST: 'DELETE_COLLECTING_SESSION_REQUEST',
  DELETE_COLLECTING_SESSION_SUCCESS: 'DELETE_COLLECTING_SESSION_SUCCESS',
  DELETE_COLLECTING_SESSION_FAILURE: 'DELETE_COLLECTING_SESSION_FAILURE',
};
