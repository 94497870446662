export default {
  SELECT_EXAMS: 'SELECT_EXAMS',
  CLEAR_EXAMS: 'CLEAR_EXAMS',
  CLEAR_EXAMINATIONS_EXCEL: 'CLEAR_EXAMINATIONS_EXCEL',

  SET_EXAMINATION_INPUT_CACHE: 'SET_EXAMINATION_INPUT_CACHE',

  GET_UNIT_INFO_REQUEST: 'GET_UNIT_INFO_REQUEST',
  GET_UNIT_INFO_SUCCESS: 'GET_UNIT_INFO_SUCCESS',
  GET_UNIT_INFO_FAILURE: 'GET_UNIT_INFO_FAILURE',

  SELECT_MEDICAL_TEST: 'SELECT_MEDICAL_TEST',

  MEDICAL_TEST_TOGGLE_CREATE_MODAL: 'MEDICAL_TEST_TOGGLE_CREATE_MODAL',
  TOGGLE_EDIT_MODAL: 'TOGGLE_EDIT_MODAL',

  GET_MEDICAL_TEST_REQUEST: 'GET_MEDICAL_TEST_REQUEST',
  GET_MEDICAL_TEST_SUCCESS: 'GET_MEDICAL_TEST_SUCCESS',
  GET_MEDICAL_TEST_FAILURE: 'GET_MEDICAL_TEST_FAILURE',

  GET_MEDICAL_TESTS_REQUEST: 'GET_MEDICAL_TESTS_REQUEST',
  GET_MEDICAL_TESTS_SUCCESS: 'GET_MEDICAL_TESTS_SUCCESS',
  GET_MEDICAL_TESTS_FAILURE: 'GET_MEDICAL_TESTS_FAILURE',

  CREATE_MEDICAL_TEST_REQUEST: 'CREATE_MEDICAL_TEST_REQUEST',
  CREATE_MEDICAL_TEST_SUCCESS: 'CREATE_MEDICAL_TEST_SUCCESS',
  CREATE_MEDICAL_TEST_FAILURE: 'CREATE_MEDICAL_TEST_FAILURE',

  UPDATE_MEDICAL_TEST_REQUEST: 'UPDATE_MEDICAL_TEST_REQUEST',
  UPDATE_MEDICAL_TEST_SUCCESS: 'UPDATE_MEDICAL_TEST_SUCCESS',
  UPDATE_MEDICAL_TEST_FAILURE: 'UPDATE_MEDICAL_TEST_FAILURE',

  DELETE_MEDICAL_TEST_REQUEST: 'DELETE_MEDICAL_TEST_REQUEST',
  DELETE_MEDICAL_TEST_SUCCESS: 'DELETE_MEDICAL_TEST_SUCCESS',
  DELETE_MEDICAL_TEST_FAILURE: 'DELETE_MEDICAL_TEST_FAILURE',

  GET_MEDICAL_TEST_ZONES_REQUEST: 'GET_MEDICAL_TEST_ZONES_REQUEST',
  GET_MEDICAL_TEST_ZONES_SUCCESS: 'GET_MEDICAL_TEST_ZONES_SUCCESS',
  GET_MEDICAL_TEST_ZONES_FAILURE: 'GET_MEDICAL_TEST_ZONES_FAILURE',

  CREATE_MEDICAL_TEST_ZONE_REQUEST: 'CREATE_MEDICAL_TEST_ZONE_REQUEST',
  CREATE_MEDICAL_TEST_ZONE_SUCCESS: 'CREATE_MEDICAL_TEST_ZONE_SUCCESS',
  CREATE_MEDICAL_TEST_ZONE_FAILURE: 'CREATE_MEDICAL_TEST_ZONE_FAILURE',

  UPDATE_MEDICAL_TEST_ZONE_REQUEST: 'UPDATE_MEDICAL_TEST_ZONE_REQUEST',
  UPDATE_MEDICAL_TEST_ZONE_SUCCESS: 'UPDATE_MEDICAL_TEST_ZONE_SUCCESS',
  UPDATE_MEDICAL_TEST_ZONE_FAILURE: 'UPDATE_MEDICAL_TEST_ZONE_FAILURE',

  DELETE_MEDICAL_TEST_ZONE_REQUEST: 'DELETE_MEDICAL_TEST_ZONE_REQUEST',
  DELETE_MEDICAL_TEST_ZONE_SUCCESS: 'DELETE_MEDICAL_TEST_ZONE_SUCCESS',
  DELETE_MEDICAL_TEST_ZONE_FAILURE: 'DELETE_MEDICAL_TEST_ZONE_FAILURE',

  GET_MEDICAL_TEST_CODES_REQUEST: 'GET_MEDICAL_TEST_CODES_REQUEST',
  GET_MEDICAL_TEST_CODES_SUCCESS: 'GET_MEDICAL_TEST_CODES_SUCCESS',
  GET_MEDICAL_TEST_CODES_FAILURE: 'GET_MEDICAL_TEST_CODES_FAILURE',

  CREATE_MEDICAL_TEST_CODE_REQUEST: 'CREATE_MEDICAL_TEST_CODE_REQUEST',
  CREATE_MEDICAL_TEST_CODE_SUCCESS: 'CREATE_MEDICAL_TEST_CODE_SUCCESS',
  CREATE_MEDICAL_TEST_CODE_FAILURE: 'CREATE_MEDICAL_TEST_CODE_FAILURE',

  UPDATE_MEDICAL_TEST_CODE_REQUEST: 'UPDATE_MEDICAL_TEST_CODE_REQUEST',
  UPDATE_MEDICAL_TEST_CODE_SUCCESS: 'UPDATE_MEDICAL_TEST_CODE_SUCCESS',
  UPDATE_MEDICAL_TEST_CODE_FAILURE: 'UPDATE_MEDICAL_TEST_CODE_FAILURE',

  DELETE_MEDICAL_TEST_CODE_REQUEST: 'DELETE_MEDICAL_TEST_CODE_REQUEST',
  DELETE_MEDICAL_TEST_CODE_SUCCESS: 'DELETE_MEDICAL_TEST_CODE_SUCCESS',
  DELETE_MEDICAL_TEST_CODE_FAILURE: 'DELETE_MEDICAL_TEST_CODE_FAILURE',

  GET_MEDICAL_TEST_ZONES_PREFIX_REQUEST:
    'GET_MEDICAL_TEST_ZONES_PREFIX_REQUEST',
  GET_MEDICAL_TEST_ZONES_PREFIX_SUCCESS:
    'GET_MEDICAL_TEST_ZONES_PREFIX_SUCCESS',
  GET_MEDICAL_TEST_ZONES_PREFIX_FAILURE:
    'GET_MEDICAL_TEST_ZONES_PREFIX_FAILURE',

  GET_MEDICAL_TEST_PRINTED_CODE_REQUEST:
    'GET_MEDICAL_TEST_PRINTED_CODE_REQUEST',
  GET_MEDICAL_TEST_PRINTED_CODE_SUCCESS:
    'GET_MEDICAL_TEST_PRINTED_CODE_SUCCESS',
  GET_MEDICAL_TEST_PRINTED_CODE_FAILURE:
    'GET_MEDICAL_TEST_PRINTED_CODE_FAILURE',

  GET_MEDICAL_TEST_ALL_ZONES_REQUEST: 'GET_MEDICAL_TEST_ALL_ZONES_REQUEST',
  GET_MEDICAL_TEST_ALL_ZONES_SUCCESS: 'GET_MEDICAL_TEST_ALL_ZONES_SUCCESS',
  GET_MEDICAL_TEST_ALL_ZONES_FAILURE: 'GET_MEDICAL_TEST_ALL_ZONES_FAILURE',

  PUBLISH_CODE_REQUEST: 'PUBLISH_CODE_REQUEST',
  PUBLISH_CODE_SUCCESS: 'PUBLISH_CODE_SUCCESS',
  PUBLISH_CODE_FAILURE: 'PUBLISH_CODE_FAILURE',

  PRINT_CODE_REQUEST: 'PRINT_CODE_REQUEST',
  PRINT_CODE_SUCCESS: 'PRINT_CODE_SUCCESS',
  PRINT_CODE_FAILURE: 'PRINT_CODE_FAILURE',

  PUBLISH_CODE_BY_ZONE_REQUEST: 'PUBLISH_CODE_BY_ZONE_REQUEST',
  PUBLISH_CODE_BY_ZONE_SUCCESS: 'PUBLISH_CODE_BY_ZONE_SUCCESS',
  PUBLISH_CODE_BY_ZONE_FAILURE: 'PUBLISH_CODE_BY_ZONE_FAILURE',

  PRINT_CODE_BY_ZONE_REQUEST: 'PRINT_CODE_BY_ZONE_REQUEST',
  PRINT_CODE_BY_ZONE_SUCCESS: 'PRINT_CODE_BY_ZONE_SUCCESS',
  PRINT_CODE_BY_ZONE_FAILURE: 'PRINT_CODE_BY_ZONE_FAILURE',

  GET_DISEASES_REQUEST: 'GET_DISEASES_REQUEST',
  GET_DISEASES_SUCCESS: 'GET_DISEASES_SUCCESS',
  GET_DISEASES_FAILURE: 'GET_DISEASES_FAILURE',

  GET_DISEASE_SAMPLES_REQUEST: 'GET_DISEASE_SAMPLES_REQUEST',
  GET_DISEASE_SAMPLES_SUCCESS: 'GET_DISEASE_SAMPLES_SUCCESS',
  GET_DISEASE_SAMPLES_FAILURE: 'GET_DISEASE_SAMPLES_FAILURE',

  GET_EXAMINATION_TYPES_REQUEST: 'GET_EXAMINATION_TYPES_REQUEST',
  GET_EXAMINATION_TYPES_SUCCESS: 'GET_EXAMINATION_TYPES_SUCCESS',
  GET_EXAMINATION_TYPES_FAILURE: 'GET_EXAMINATION_TYPES_FAILURE',

  GET_EXAMINATIONS_REQUEST: 'GET_EXAMINATIONS_REQUEST',
  GET_EXAMINATIONS_SUCCESS: 'GET_EXAMINATIONS_SUCCESS',
  GET_EXAMINATIONS_FAILURE: 'GET_EXAMINATIONS_FAILURE',

  CREATE_EXAMINATION_REQUEST: 'CREATE_EXAMINATION_REQUEST',
  CREATE_EXAMINATION_SUCCESS: 'CREATE_EXAMINATION_SUCCESS',
  CREATE_EXAMINATION_FAILURE: 'CREATE_EXAMINATION_FAILURE',

  UPDATE_EXAMINATION_REQUEST: 'UPDATE_EXAMINATION_REQUEST',
  UPDATE_EXAMINATION_SUCCESS: 'UPDATE_EXAMINATION_SUCCESS',
  UPDATE_EXAMINATION_FAILURE: 'UPDATE_EXAMINATION_FAILURE',

  DELETE_EXAMINATION_REQUEST: 'DELETE_EXAMINATION_REQUEST',
  DELETE_EXAMINATION_SUCCESS: 'DELETE_EXAMINATION_SUCCESS',
  DELETE_EXAMINATION_FAILURE: 'DELETE_EXAMINATION_FAILURE',

  GET_EXAMINATION_BY_PERSON_REQUEST: 'GET_EXAMINATION_BY_PERSON_REQUEST',
  GET_EXAMINATION_BY_PERSON_SUCCESS: 'GET_EXAMINATION_BY_PERSON_SUCCESS',
  GET_EXAMINATION_BY_PERSON_FAILURE: 'GET_EXAMINATION_BY_PERSON_FAILURE',

  GET_EXAMINATION_DETAILS_REQUEST: 'GET_EXAMINATION_DETAILS_REQUEST',
  GET_EXAMINATION_DETAILS_SUCCESS: 'GET_EXAMINATION_DETAILS_SUCCESS',
  GET_EXAMINATION_DETAILS_FAILURE: 'GET_EXAMINATION_DETAILS_FAILURE',

  GET_AVAILABLE_CODES_REQUEST: 'GET_AVAILABLE_CODES_REQUEST',
  GET_AVAILABLE_CODES_SUCCESS: 'GET_AVAILABLE_CODES_SUCCESS',
  GET_AVAILABLE_CODES_FAILURE: 'GET_AVAILABLE_CODES_FAILURE',

  GET_UNAVAILABLE_CODES_REQUEST: 'GET_UNAVAILABLE_CODES_REQUEST',
  GET_UNAVAILABLE_CODES_SUCCESS: 'GET_UNAVAILABLE_CODES_SUCCESS',
  GET_UNAVAILABLE_CODES_FAILURE: 'GET_UNAVAILABLE_CODES_FAILURE',

  CREATE_EXAMINATION_CODE_REQUEST: 'CREATE_EXAMINATION_CODE_REQUEST',
  CREATE_EXAMINATION_CODE_SUCCESS: 'CREATE_EXAMINATION_CODE_SUCCESS',
  CREATE_EXAMINATION_CODE_FAILURE: 'CREATE_EXAMINATION_CODE_FAILURE',

  GET_UNITS_REQUEST: 'GET_UNITS_REQUEST',
  GET_UNITS_SUCCESS: 'GET_UNITS_SUCCESS',
  GET_UNITS_FAILURE: 'GET_UNITS_FAILURE',

  GET_ASSIGNEES_BY_UNIT_REQUEST: 'GET_ASSIGNEES_BY_UNIT_REQUEST',
  GET_ASSIGNEES_BY_UNIT_SUCCESS: 'GET_ASSIGNEES_BY_UNIT_SUCCESS',
  GET_ASSIGNEES_BY_UNIT_FAILURE: 'GET_ASSIGNEES_BY_UNIT_FAILURE',

  ASSIGN_WITH_CODE_ONLY_REQUEST: 'ASSIGN_WITH_CODE_ONLY_REQUEST',
  ASSIGN_WITH_CODE_ONLY_SUCCESS: 'ASSIGN_WITH_CODE_ONLY_SUCCESS',
  ASSIGN_WITH_CODE_ONLY_FAILURE: 'ASSIGN_WITH_CODE_ONLY_FAILURE',

  GET_UNIT_TYPES_REQUEST: 'GET_UNIT_TYPES_REQUEST',
  GET_UNIT_TYPES_SUCCESS: 'GET_UNIT_TYPES_SUCCESS',
  GET_UNIT_TYPES_FAILURE: 'GET_UNIT_TYPES_FAILURE',

  CREATE_UNIT_TYPE_REQUEST: 'CREATE_UNIT_TYPE_REQUEST',
  CREATE_UNIT_TYPE_SUCCESS: 'CREATE_UNIT_TYPE_SUCCESS',
  CREATE_UNIT_TYPE_FAILURE: 'CREATE_UNIT_TYPE_FAILURE',

  GET_PREFIXES_REQUEST: 'GET_PREFIXES_REQUEST',
  GET_PREFIXES_SUCCESS: 'GET_PREFIXES_SUCCESS',
  GET_PREFIXES_FAILURE: 'GET_PREFIXES_FAILURE',

  CREATE_UNIT_REQUEST: 'CREATE_UNIT_REQUEST',
  CREATE_UNIT_SUCCESS: 'CREATE_UNIT_SUCCESS',
  CREATE_UNIT_FAILURE: 'CREATE_UNIT_FAILURE',

  UPDATE_UNIT_REQUEST: 'UPDATE_UNIT_REQUEST',
  UPDATE_UNIT_SUCCESS: 'UPDATE_UNIT_SUCCESS',
  UPDATE_UNIT_FAILURE: 'UPDATE_UNIT_FAILURE',

  DELETE_UNIT_REQUEST: 'DELETE_UNIT_REQUEST',
  DELETE_UNIT_SUCCESS: 'DELETE_UNIT_SUCCESS',
  DELETE_UNIT_FAILURE: 'DELETE_UNIT_FAILURE',

  CREATE_ASSIGN_REQUEST: 'CREATE_ASSIGN_REQUEST',
  CREATE_ASSIGN_SUCCESS: 'CREATE_ASSIGN_SUCCESS',
  CREATE_ASSIGN_FAILURE: 'CREATE_ASSIGN_FAILURE',

  UPDATE_ASSIGN_REQUEST: 'UPDATE_ASSIGN_REQUEST',
  UPDATE_ASSIGN_SUCCESS: 'UPDATE_ASSIGN_SUCCESS',
  UPDATE_ASSIGN_FAILURE: 'UPDATE_ASSIGN_FAILURE',

  CANCEL_ASSIGN_REQUEST: 'CANCEL_ASSIGN_REQUEST',
  CANCEL_ASSIGN_SUCCESS: 'CANCEL_ASSIGN_SUCCESS',
  CANCEL_ASSIGN_FAILURE: 'CANCEL_ASSIGN_FAILURE',

  GET_ASSIGNEES_REQUEST: 'GET_ASSIGNEES_REQUEST',
  GET_ASSIGNEES_SUCCESS: 'GET_ASSIGNEES_SUCCESS',
  GET_ASSIGNEES_FAILURE: 'GET_ASSIGNEES_FAILURE',

  GET_TRANSPORTS_REQUEST: 'GET_TRANSPORTS_REQUEST',
  GET_TRANSPORTS_SUCCESS: 'GET_TRANSPORTS_SUCCESS',
  GET_TRANSPORTS_FAILURE: 'GET_TRANSPORTS_FAILURE',

  GET_TRANSPORT_BY_ID_REQUEST: 'GET_TRANSPORT_BY_ID_REQUEST',
  GET_TRANSPORT_BY_ID_SUCCESS: 'GET_TRANSPORT_BY_ID_SUCCESS',
  GET_TRANSPORT_BY_ID_FAILURE: 'GET_TRANSPORT_BY_ID_FAILURE',

  CREATE_TRANSPORT_REQUEST: 'CREATE_TRANSPORT_REQUEST',
  CREATE_TRANSPORT_SUCCESS: 'CREATE_TRANSPORT_SUCCESS',
  CREATE_TRANSPORT_FAILURE: 'CREATE_TRANSPORT_FAILURE',

  UPDATE_TRANSPORT_REQUEST: 'UPDATE_TRANSPORT_REQUEST',
  UPDATE_TRANSPORT_SUCCESS: 'UPDATE_TRANSPORT_SUCCESS',
  UPDATE_TRANSPORT_FAILURE: 'UPDATE_TRANSPORT_FAILURE',

  DELETE_TRANSPORT_REQUEST: 'DELETE_TRANSPORT_REQUEST',
  DELETE_TRANSPORT_SUCCESS: 'DELETE_TRANSPORT_SUCCESS',
  DELETE_TRANSPORT_FAILURE: 'DELETE_TRANSPORT_FAILURE',

  SEND_TRANSPORT_REQUEST: 'SEND_TRANSPORT_REQUEST',
  SEND_TRANSPORT_SUCCESS: 'SEND_TRANSPORT_SUCCESS',
  SEND_TRANSPORT_FAILURE: 'SEND_TRANSPORT_FAILURE',

  RECEIVE_TRANSPORT_REQUEST: 'RECEIVE_TRANSPORT_REQUEST',
  RECEIVE_TRANSPORT_SUCCESS: 'RECEIVE_TRANSPORT_SUCCESS',
  RECEIVE_TRANSPORT_FAILURE: 'RECEIVE_TRANSPORT_FAILURE',

  GET_UNITS_AVAILABLE_REQUEST: 'GET_UNITS_AVAILABLE_REQUEST',
  GET_UNITS_AVAILABLE_SUCCESS: 'GET_UNITS_AVAILABLE_SUCCESS',
  GET_UNITS_AVAILABLE_FAILURE: 'GET_UNITS_AVAILABLE_FAILURE',

  GET_AVAILABLE_CODE_TO_PRINT_REQUEST: 'GET_AVAILABLE_CODE_TO_PRINT_REQUEST',
  GET_AVAILABLE_CODE_TO_PRINT_SUCCESS: 'GET_AVAILABLE_CODE_TO_PRINT_SUCCESS',
  GET_AVAILABLE_CODE_TO_PRINT_FAILURE: 'GET_AVAILABLE_CODE_TO_PRINT_FAILURE',

  GET_AVAILABLE_CODE_TO_PUBLISH_REQUEST:
    'GET_AVAILABLE_CODE_TO_PUBLISH_REQUEST',
  GET_AVAILABLE_CODE_TO_PUBLISH_SUCCESS:
    'GET_AVAILABLE_CODE_TO_PUBLISH_SUCCESS',
  GET_AVAILABLE_CODE_TO_PUBLISH_FAILURE:
    'GET_AVAILABLE_CODE_TO_PUBLISH_FAILURE',

  GET_AVAILABLE_DISEASE_TO_PRINT_REQUEST:
    'GET_AVAILABLE_DISEASE_TO_PRINT_REQUEST',
  GET_AVAILABLE_DISEASE_TO_PRINT_SUCCESS:
    'GET_AVAILABLE_DISEASE_TO_PRINT_SUCCESS',
  GET_AVAILABLE_DISEASE_TO_PRINT_FAILURE:
    'GET_AVAILABLE_DISEASE_TO_PRINT_FAILURE',

  GET_AVAILABLE_DISEASE_TO_PUBLISH_REQUEST:
    'GET_AVAILABLE_DISEASE_TO_PUBLISH_REQUEST',
  GET_AVAILABLE_DISEASE_TO_PUBLISH_SUCCESS:
    'GET_AVAILABLE_DISEASE_TO_PUBLISH_SUCCESS',
  GET_AVAILABLE_DISEASE_TO_PUBLISH_FAILURE:
    'GET_AVAILABLE_DISEASE_TO_PUBLISH_FAILURE',

  REPRINT_CODE_BY_ZONE_REQUEST: 'REPRINT_CODE_BY_ZONE_REQUEST',
  REPRINT_CODE_BY_ZONE_SUCCESS: 'REPRINT_CODE_BY_ZONE_SUCCESS',
  REPRINT_CODE_BY_ZONE_FAILURE: 'REPRINT_CODE_BY_ZONE_FAILURE',

  REPRINT_CODE_FROM_REQUEST: 'REPRINT_CODE_FROM_REQUEST',
  REPRINT_CODE_FROM_SUCCESS: 'REPRINT_CODE_FROM_SUCCESS',
  REPRINT_CODE_FROM_FAILURE: 'REPRINT_CODE_FROM_FAILURE',

  GET_REPRINT_DISEASE_REQUEST: 'GET_REPRINT_DISEASE_REQUEST',
  GET_REPRINT_DISEASE_SUCCESS: 'GET_REPRINT_DISEASE_SUCCESS',
  GET_REPRINT_DISEASE_FAILURE: 'GET_REPRINT_DISEASE_FAILURE',

  GET_EXAMINATION_DETAIL_TEMPS_REQUEST: 'GET_EXAMINATION_DETAIL_TEMPS_REQUEST',
  GET_EXAMINATION_DETAIL_TEMPS_SUCCESS: 'GET_EXAMINATION_DETAIL_TEMPS_SUCCESS',
  GET_EXAMINATION_DETAIL_TEMPS_FAILURE: 'GET_EXAMINATION_DETAIL_TEMPS_FAILURE',

  GET_POSITIVE_EXAMINATION_DETAIL_REQUEST:
    'GET_POSITIVE_EXAMINATION_DETAIL_REQUEST',
  GET_POSITIVE_EXAMINATION_DETAIL_SUCCESS:
    'GET_POSITIVE_EXAMINATION_DETAIL_SUCCESS',
  GET_POSITIVE_EXAMINATION_DETAIL_FAILURE:
    'GET_POSITIVE_EXAMINATION_DETAIL_FAILURE',

  GET_EXAMINATION_FOR_TRANSPORTS_NORMAL_REQUEST:
    'GET_EXAMINATION_FOR_TRANSPORTS_NORMAL_REQUEST',
  GET_EXAMINATION_FOR_TRANSPORTS_NORMAL_SUCCESS:
    'GET_EXAMINATION_FOR_TRANSPORTS_NORMAL_SUCCESS',
  GET_EXAMINATION_FOR_TRANSPORTS_NORMAL_FAILURE:
    'GET_EXAMINATION_FOR_TRANSPORTS_NORMAL_FAILURE',

  GET_EXAMINATION_FOR_TRANSPORTS_URGENCY_REQUEST:
    'GET_EXAMINATION_FOR_TRANSPORTS_URGENCY_REQUEST',
  GET_EXAMINATION_FOR_TRANSPORTS_URGENCY_SUCCESS:
    'GET_EXAMINATION_FOR_TRANSPORTS_URGENCY_SUCCESS',
  GET_EXAMINATION_FOR_TRANSPORTS_URGENCY_FAILURE:
    'GET_EXAMINATION_FOR_TRANSPORTS_URGENCY_FAILURE',

  UPDATE_EXAM_DETAIL_REQUEST: 'UPDATE_EXAM_DETAIL_REQUEST',
  UPDATE_EXAM_DETAIL_SUCCESS: 'UPDATE_EXAM_DETAIL_SUCCESS',
  UPDATE_EXAM_DETAIL_FAILURE: 'UPDATE_EXAM_DETAIL_FAILURE',

  GET_USED_CODE_REQUEST: 'GET_USED_CODE_REQUEST',
  GET_USED_CODE_SUCCESS: 'GET_USED_CODE_SUCCESS',
  GET_USED_CODE_FAILURE: 'GET_USED_CODE_FAILURE',

  GET_OTHER_CODE_REQUEST: 'GET_OTHER_CODE_REQUEST',
  GET_OTHER_CODE_SUCCESS: 'GET_OTHER_CODE_SUCCESS',
  GET_OTHER_CODE_FAILURE: 'GET_OTHER_CODE_FAILURE',

  GET_REPRINT_CODE_REQUEST: 'GET_REPRINT_CODE_REQUEST',
  GET_REPRINT_CODE_SUCCESS: 'GET_REPRINT_CODE_SUCCESS',
  GET_REPRINT_CODE_FAILURE: 'GET_REPRINT_CODE_FAILURE',

  UPLOAD_TRANSPORT_FILE_REQUEST: 'UPLOAD_TRANSPORT_FILE_REQUEST',
  UPLOAD_TRANSPORT_FILE_SUCCESS: 'UPLOAD_TRANSPORT_FILE_SUCCESS',
  UPLOAD_TRANSPORT_FILE_FAILURE: 'UPLOAD_TRANSPORT_FILE_FAILURE',
  SET_UPLOAD_TRANSPORT_FILE_PROGRESS: 'SET_UPLOAD_TRANSPORT_FILE_PROGRESS',

  UPLOAD_EXAMINATION_FILE_REQUEST: 'UPLOAD_EXAMINATION_FILE_REQUEST',
  UPLOAD_EXAMINATION_FILE_SUCCESS: 'UPLOAD_EXAMINATION_FILE_SUCCESS',
  UPLOAD_EXAMINATION_FILE_FAILURE: 'UPLOAD_EXAMINATION_FILE_FAILURE',
  SET_UPLOAD_EXAMINATION_FILE_PROGRESS: 'SET_UPLOAD_EXAMINATION_FILE_PROGRESS',

  CREATE_BATCH_UNIT_REQUEST: 'CREATE_BATCH_UNIT_REQUEST',
  CREATE_BATCH_UNIT_SUCCESS: 'CREATE_BATCH_UNIT_SUCCESS',
  CREATE_BATCH_UNIT_FAILURE: 'CREATE_BATCH_UNIT_FAILURE',

  GET_AVAILABLE_UNITS_TO_PUBLISH_REQUEST:
    'GET_AVAILABLE_UNITS_TO_PUBLISH_REQUEST',
  GET_AVAILABLE_UNITS_TO_PUBLISH_SUCCESS:
    'GET_AVAILABLE_UNITS_TO_PUBLISH_SUCCESS',
  GET_AVAILABLE_UNITS_TO_PUBLISH_FAILURE:
    'GET_AVAILABLE_UNITS_TO_PUBLISH_FAILURE',

  PUBLISH_BATCH_UNIT_REQUEST: 'PUBLISH_BATCH_UNIT_REQUEST',
  PUBLISH_BATCH_UNIT_SUCCESS: 'PUBLISH_BATCH_UNIT_SUCCESS',
  PUBLISH_BATCH_UNIT_FAILURE: 'PUBLISH_BATCH_UNIT_FAILURE',

  EXPORT_TRANSPORT_FILE_REQUEST: 'EXPORT_TRANSPORT_FILE_REQUEST',
  EXPORT_TRANSPORT_FILE_SUCCESS: 'EXPORT_TRANSPORT_FILE_SUCCESS',
  EXPORT_TRANSPORT_FILE_FAILURE: 'EXPORT_TRANSPORT_FILE_FAILURE',

  GET_SESSIONS_REQUEST: 'GET_SESSIONS_REQUEST',
  GET_SESSIONS_SUCCESS: 'GET_SESSIONS_SUCCESS',
  GET_SESSIONS_FAILURE: 'GET_SESSIONS_FAILURE',

  CREATE_SESSION_REQUEST: 'CREATE_SESSION_REQUEST',
  CREATE_SESSION_SUCCESS: 'CREATE_SESSION_SUCCESS',
  CREATE_SESSION_FAILURE: 'CREATE_SESSION_FAILURE',

  UPDATE_SESSION_REQUEST: 'UPDATE_SESSION_REQUEST',
  UPDATE_SESSION_SUCCESS: 'UPDATE_SESSION_SUCCESS',
  UPDATE_SESSION_FAILURE: 'UPDATE_SESSION_FAILURE',

  DELETE_SESSION_REQUEST: 'DELETE_SESSION_REQUEST',
  DELETE_SESSION_SUCCESS: 'DELETE_SESSION_SUCCESS',
  DELETE_SESSION_FAILURE: 'DELETE_SESSION_FAILURE',

  GET_AVAILABLE_DISEASE_EXAMBOX_REQUEST:
    'GET_AVAILABLE_DISEASE_EXAMBOX_REQUEST',
  GET_AVAILABLE_DISEASE_EXAMBOX_SUCCESS:
    'GET_AVAILABLE_DISEASE_EXAMBOX_SUCCESS',
  GET_AVAILABLE_DISEASE_EXAMBOX_FAILURE:
    'GET_AVAILABLE_DISEASE_EXAMBOX_FAILURE',

  GET_AVAILABLE_EXAMBOX_REQUEST: 'GET_AVAILABLE_EXAMBOX_REQUEST',
  GET_AVAILABLE_EXAMBOX_SUCCESS: 'GET_AVAILABLE_EXAMBOX_SUCCESS',
  GET_AVAILABLE_EXAMBOX_FAILURE: 'GET_AVAILABLE_EXAMBOX_FAILURE',

  EXPORT_NONE_RESULT_EXCEL_DETAILS_REQUEST:
    'EXPORT_NONE_RESULT_EXCEL_DETAILS_REQUEST',
  EXPORT_NONE_RESULT_EXCEL_DETAILS_SUCCESS:
    'EXPORT_NONE_RESULT_EXCEL_DETAILS_SUCCESS',
  EXPORT_NONE_RESULT_EXCEL_DETAILS_FAILURE:
    'EXPORT_NONE_RESULT_EXCEL_DETAILS_FAILURE',

  EXPORT_EXAM_FILE_REQUEST: 'EXPORT_EXAM_FILE_REQUEST',
  EXPORT_EXAM_FILE_SUCCESS: 'EXPORT_EXAM_FILE_SUCCESS',
  EXPORT_EXAM_FILE_FAILURE: 'EXPORT_EXAM_FILE_FAILURE',

  GET_EXAMINATION_DETAILS_AVAILABLE_FOR_TEST_SESSION_REQUEST:
    'GET_EXAMINATION_DETAILS_AVAILABLE_FOR_TEST_SESSION_REQUEST',
  GET_EXAMINATION_DETAILS_AVAILABLE_FOR_TEST_SESSION_SUCCESS:
    'GET_EXAMINATION_DETAILS_AVAILABLE_FOR_TEST_SESSION_SUCCESS',
  GET_EXAMINATION_DETAILS_AVAILABLE_FOR_TEST_SESSION_FAILURE:
    'GET_EXAMINATION_DETAILS_AVAILABLE_FOR_TEST_SESSION_FAILURE',

  GET_ALL_EXAMINATION_DETAILS_AVAILABLE_FOR_TEST_SESSION_REQUEST:
    'GET_ALL_EXAMINATION_DETAILS_AVAILABLE_FOR_TEST_SESSION_REQUEST',
  GET_ALL_EXAMINATION_DETAILS_AVAILABLE_FOR_TEST_SESSION_SUCCESS:
    'GET_ALL_EXAMINATION_DETAILS_AVAILABLE_FOR_TEST_SESSION_SUCCESS',
  GET_ALL_EXAMINATION_DETAILS_AVAILABLE_FOR_TEST_SESSION_FAILURE:
    'GET_ALL_EXAMINATION_DETAILS_AVAILABLE_FOR_TEST_SESSION_FAILURE',

  GET_PLATE_AUTO_FILL_REQUEST: 'GET_PLATE_AUTO_FILL_REQUEST',
  GET_PLATE_AUTO_FILL_SUCCESS: 'GET_PLATE_AUTO_FILL_SUCCESS',
  GET_PLATE_AUTO_FILL_FAILURE: 'GET_PLATE_AUTO_FILL_FAILURE',

  EXPORT_PLATE_REQUEST: 'EXPORT_PLATE_REQUEST',
  EXPORT_PLATE_SUCCESS: 'EXPORT_PLATE_SUCCESS',
  EXPORT_PLATE_FAILURE: 'EXPORT_PLATE_FAILURE',

  EXPORT_PLATE_RESULT_REQUEST: 'EXPORT_PLATE_RESULT_REQUEST',
  EXPORT_PLATE_RESULT_SUCCESS: 'EXPORT_PLATE_RESULT_SUCCESS',
  EXPORT_PLATE_RESULT_FAILURE: 'EXPORT_PLATE_RESULT_FAILURE',

  EXPORT_EXAMIANATION_RESULT_REQUEST: 'EXPORT_EXAMIANATION_RESULT_REQUEST',
  EXPORT_EXAMIANATION_RESULT_SUCCESS: 'EXPORT_EXAMIANATION_RESULT_SUCCESS',
  EXPORT_EXAMIANATION_RESULT_FAILURE: 'EXPORT_EXAMIANATION_RESULT_FAILURE',

  GET_AVAILABLE_DAY_BY_UNIT_FOR_EXPORT_EXAMINATION_REQUEST:
    'GET_AVAILABLE_DAY_BY_UNIT_FOR_EXPORT_EXAMINATION_REQUEST',
  GET_AVAILABLE_DAY_BY_UNIT_FOR_EXPORT_EXAMINATION_SUCCESS:
    'GET_AVAILABLE_DAY_BY_UNIT_FOR_EXPORT_EXAMINATION_SUCCESS',
  GET_AVAILABLE_DAY_BY_UNIT_FOR_EXPORT_EXAMINATION_FAILURE:
    'GET_AVAILABLE_DAY_BY_UNIT_FOR_EXPORT_EXAMINATION_FAILURE',

  UPLOAD_PLATE_RESULT_REQUEST: 'UPLOAD_PLATE_RESULT_REQUEST',
  UPLOAD_PLATE_RESULT_SUCCESS: 'UPLOAD_PLATE_RESULT_SUCCESS',
  UPLOAD_PLATE_RESULT_FAILURE: 'UPLOAD_PLATE_RESULT_FAILURE',
  SET_UPLOAD_PLATE_RESULT_PROGRESS: 'SET_UPLOAD_PLATE_RESULT_PROGRESS',

  GET_BY_PROFILE_ID_REQUEST: 'GET_BY_PROFILE_ID_REQUEST',
  GET_BY_PROFILE_ID_SUCCESS: 'GET_BY_PROFILE_ID_SUCCESS',
  GET_BY_PROFILE_ID_FAILURE: 'GET_BY_PROFILE_ID_FAILURE',

  MERGE_PROFILE_REQUEST: 'MERGE_PROFILE_REQUEST',
  MERGE_PROFILE_SUCCESS: 'MERGE_PROFILE_SUCCESS',
  MERGE_PROFILE_FAILURE: 'MERGE_PROFILE_FAILURE',

  CREATE_PROFILE_FROM_EXAMINATION_REQUEST:
    'CREATE_PROFILE_FROM_EXAMINATION_REQUEST',
  CREATE_PROFILE_FROM_EXAMINATION_SUCCESS:
    'CREATE_PROFILE_FROM_EXAMINATION_SUCCESS',
  CREATE_PROFILE_FROM_EXAMINATION_FAILURE:
    'CREATE_PROFILE_FROM_EXAMINATION_FAILURE',

  GET_SESSION_DETAIL_REQUEST: 'GET_SESSION_DETAIL_REQUEST',
  GET_SESSION_DETAIL_SUCCESS: 'GET_SESSION_DETAIL_SUCCESS',
  GET_SESSION_DETAIL_FAILURE: 'GET_SESSION_DETAIL_FAILURE',

  GET_TRANSPORT_DETAIL_REQUEST: 'GET_TRANSPORT_DETAIL_REQUEST',
  GET_TRANSPORT_DETAIL_SUCCESS: 'GET_TRANSPORT_DETAIL_SUCCESS',
  GET_TRANSPORT_DETAIL_FAILURE: 'GET_TRANSPORT_DETAIL_FAILURE',

  IMPORT_INFORMATION_REQUEST: 'IMPORT_INFORMATION_REQUEST',
  IMPORT_INFORMATION_SUCCESS: 'IMPORT_INFORMATION_SUCCESS',
  IMPORT_INFORMATION_FAILURE: 'IMPORT_INFORMATION_FAILURE',

  EXPORT_EXAM_BOOK_REQUEST: 'EXPORT_EXAM_BOOK_REQUEST',
  EXPORT_EXAM_BOOK_SUCCESS: 'EXPORT_EXAM_BOOK_SUCCESS',
  EXPORT_EXAM_BOOK_FAILURE: 'EXPORT_EXAM_BOOK_FAILURE',

  GET_EXAMINATION_BY_DETAIL_REQUEST: 'GET_EXAMINATION_BY_DETAIL_REQUEST',
  GET_EXAMINATION_BY_DETAIL_SUCCESS: 'GET_EXAMINATION_BY_DETAIL_SUCCESS',
  GET_EXAMINATION_BY_DETAIL_FAILURE: 'GET_EXAMINATION_BY_DETAIL_FAILURE',

  UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

  EXPORT_EXAMINATION_RESULT_REQUEST: 'EXPORT_EXAMINATION_RESULT_REQUEST',
  EXPORT_EXAMINATION_RESULT_SUCCESS: 'EXPORT_EXAMINATION_RESULT_SUCCESS',
  EXPORT_EXAMINATION_RESULT_FAILURE: 'EXPORT_EXAMINATION_RESULT_FAILURE',

  GET_UNIT_CONFIGS_REQUEST: 'GET_UNIT_CONFIGS_REQUEST',
  GET_UNIT_CONFIGS_SUCCESS: 'GET_UNIT_CONFIGS_SUCCESS',
  GET_UNIT_CONFIGS_FAILURE: 'GET_UNIT_CONFIGS_FAILURE',

  CREATE_UNIT_CONFIG_REQUEST: 'CREATE_UNIT_CONFIG_REQUEST',
  CREATE_UNIT_CONFIG_SUCCESS: 'CREATE_UNIT_CONFIG_SUCCESS',
  CREATE_UNIT_CONFIG_FAILURE: 'CREATE_UNIT_CONFIG_FAILURE',

  UPDATE_UNIT_CONFIG_REQUEST: 'UPDATE_UNIT_CONFIG_REQUEST',
  UPDATE_UNIT_CONFIG_SUCCESS: 'UPDATE_UNIT_CONFIG_SUCCESS',
  UPDATE_UNIT_CONFIG_FAILURE: 'UPDATE_UNIT_CONFIG_FAILURE',

  DELETE_UNIT_CONFIG_REQUEST: 'DELETE_UNIT_CONFIG_REQUEST',
  DELETE_UNIT_CONFIG_SUCCESS: 'DELETE_UNIT_CONFIG_SUCCESS',
  DELETE_UNIT_CONFIG_FAILURE: 'DELETE_UNIT_CONFIG_FAILURE',

  CHANGE_PROFILE_REQUEST: 'CHANGE_PROFILE_REQUEST',
  CHANGE_PROFILE_SUCCESS: 'CHANGE_PROFILE_SUCCESS',
  CHANGE_PROFILE_FAILURE: 'CHANGE_PROFILE_FAILURE',

  GET_PERSONAL_EXAM_HISTORY_REQUEST: 'GET_PERSONAL_EXAM_HISTORY_REQUEST',
  GET_PERSONAL_EXAM_HISTORY_SUCCESS: 'GET_PERSONAL_EXAM_HISTORY_SUCCESS',
  GET_PERSONAL_EXAM_HISTORY_FAILURE: 'GET_PERSONAL_EXAM_HISTORY_FAILURE',

  UPLOAD_PROFILES_FROM_EXCEL_REQUEST: 'UPLOAD_PROFILES_FROM_EXCEL_REQUEST',
  UPLOAD_PROFILES_FROM_EXCEL_SUCCESS: 'UPLOAD_PROFILES_FROM_EXCEL_SUCCESS',
  UPLOAD_PROFILES_FROM_EXCEL_FAILURE: 'UPLOAD_PROFILES_FROM_EXCEL_FAILURE',

  CHANGE_PROFILE_PATCH_REQUEST: 'CHANGE_PROFILE_PATCH_REQUEST',
  CHANGE_PROFILE_PATCH_SUCCESS: 'CHANGE_PROFILE_PATCH_SUCCESS',
  CHANGE_PROFILE_PATCH_FAILURE: 'CHANGE_PROFILE_PATCH_FAILURE',

  UPDATE_SESSION_RESULT_REQUEST: 'UPDATE_SESSION_RESULT_REQUEST',
  UPDATE_SESSION_RESULT_SUCCESS: 'UPDATE_SESSION_RESULT_SUCCESS',
  UPDATE_SESSION_RESULT_FAILURE: 'UPDATE_SESSION_RESULT_FAILURE',

  SESSION_TESTING_REQUEST: 'SESSION_TESTING_REQUEST',
  SESSION_TESTING_SUCCESS: 'SESSION_TESTING_SUCCESS',
  SESSION_TESTING_FAILURE: 'SESSION_TESTING_FAILURE',

  GET_AVAILABLE_EXAM_FOR_TRANSPORT_REQUEST:
    'GET_AVAILABLE_EXAM_FOR_TRANSPORT_REQUEST',
  GET_AVAILABLE_EXAM_FOR_TRANSPORT_SUCCESS:
    'GET_AVAILABLE_EXAM_FOR_TRANSPORT_SUCCESS',
  GET_AVAILABLE_EXAM_FOR_TRANSPORT_FAILURE:
    'GET_AVAILABLE_EXAM_FOR_TRANSPORT_FAILURE',

  UPLOAD_TRANSPORT_EXCEL_REQUEST: 'UPLOAD_TRANSPORT_EXCEL_REQUEST',
  UPLOAD_TRANSPORT_EXCEL_SUCCESS: 'UPLOAD_TRANSPORT_EXCEL_SUCCESS',
  UPLOAD_TRANSPORT_EXCEL_FAILURE: 'UPLOAD_TRANSPORT_EXCEL_FAILURE',

  EXPORT_RESULT_FROM_EXCEL_REQUEST: 'EXPORT_RESULT_FROM_EXCEL_REQUEST',
  EXPORT_RESULT_FROM_EXCEL_SUCCESS: 'EXPORT_RESULT_FROM_EXCEL_SUCCESS',
  EXPORT_RESULT_FROM_EXCEL_FAILURE: 'EXPORT_RESULT_FROM_EXCEL_FAILURE',

  IMPORT_ASSIGNS_REQUEST: 'IMPORT_ASSIGNS_REQUEST',
  IMPORT_ASSIGNS_SUCCESS: 'IMPORT_ASSIGNS_SUCCESS',
  IMPORT_ASSIGNS_FAILURE: 'IMPORT_ASSIGNS_FAILURE',

  GET_AVAILABLE_CODES_TO_USE_REQUEST: 'GET_AVAILABLE_CODES_TO_USE_REQUEST',
  GET_AVAILABLE_CODES_TO_USE_SUCCESS: 'GET_AVAILABLE_CODES_TO_USE_SUCCESS',
  GET_AVAILABLE_CODES_TO_USE_FAILURE: 'GET_AVAILABLE_CODES_TO_USE_FAILURE',

  CREATE_GROUP_PROFILE_REQUEST: 'CREATE_GROUP_PROFILE_REQUEST',
  CREATE_GROUP_PROFILE_SUCCESS: 'CREATE_GROUP_PROFILE_SUCCESS',
  CREATE_GROUP_PROFILE_FAILURE: 'CREATE_GROUP_PROFILE_FAILURE',

  CREATE_AND_UPDATE_RESULT_REQUEST: 'CREATE_AND_UPDATE_RESULT_REQUEST',
  CREATE_AND_UPDATE_RESULT_SUCCESS: 'CREATE_AND_UPDATE_RESULT_SUCCESS',
  CREATE_AND_UPDATE_RESULT_FAILURE: 'CREATE_AND_UPDATE_RESULT_FAILURE',

  QUICK_RECEIVE_TRANSPORT_REQUEST: 'QUICK_RECEIVE_TRANSPORT_REQUEST',
  QUICK_RECEIVE_TRANSPORT_SUCCESS: 'QUICK_RECEIVE_TRANSPORT_SUCCESS',
  QUICK_RECEIVE_TRANSPORT_FAILURE: 'QUICK_RECEIVE_TRANSPORT_FAILURE',

  GET_SAMPLING_PLACES_REQUEST: 'GET_SAMPLING_PLACES_REQUEST',
  GET_SAMPLING_PLACES_SUCCESS: 'GET_SAMPLING_PLACES_SUCCESS',
  GET_SAMPLING_PLACES_FAILURE: 'GET_SAMPLING_PLACES_FAILURE',

  MASK_AS_UNSATISFACTORY_SAMPLE_REQUEST: 'MASK_AS_UNSATISFACTORY_SAMPLE_REQUEST',
  MASK_AS_UNSATISFACTORY_SAMPLE_SUCCESS: 'MASK_AS_UNSATISFACTORY_SAMPLE_SUCCESS',
  MASK_AS_UNSATISFACTORY_SAMPLE_FAILURE: 'MASK_AS_UNSATISFACTORY_SAMPLE_FAILURE',

  UN_MASK_AS_UNSATISFACTORY_SAMPLE_REQUEST: 'UN_MASK_AS_UNSATISFACTORY_SAMPLE_REQUEST',
  UN_MASK_AS_UNSATISFACTORY_SAMPLE_SUCCESS: 'UN_MASK_AS_UNSATISFACTORY_SAMPLE_SUCCESS',
  UN_MASK_AS_UNSATISFACTORY_SAMPLE_FAILURE: 'UN_MASK_AS_UNSATISFACTORY_SAMPLE_FAILURE',

  EXPORT_STATISTIC_EXAMINATION_BY_CODE_REQUEST:
    'EXPORT_STATISTIC_EXAMINATION_BY_CODE_REQUEST',
  EXPORT_STATISTIC_EXAMINATION_BY_CODE_SUCCESS:
    'EXPORT_STATISTIC_EXAMINATION_BY_CODE_SUCCESS',
  EXPORT_STATISTIC_EXAMINATION_BY_CODE_FAILURE:
    'EXPORT_STATISTIC_EXAMINATION_BY_CODE_FAILURE',

  CLEAR_EXAMINATION_DETAIL_FILTER: 'CLEAR_EXAMINATION_DETAIL_FILTER',

  GET_INFECTED_PATIENTS_REQUEST: 'GET_INFECTED_PATIENTS_REQUEST',
  GET_INFECTED_PATIENTS_SUCCESS: 'GET_INFECTED_PATIENTS_SUCCESS',
  GET_INFECTED_PATIENTS_FAILURE: 'GET_INFECTED_PATIENTS_FAILURE',

  GET_INFECTED_PATIENT_DETAIL_REQUEST: 'GET_INFECTED_PATIENT_DETAIL_REQUEST',
  GET_INFECTED_PATIENT_DETAIL_SUCCESS: 'GET_INFECTED_PATIENT_DETAIL_SUCCESS',
  GET_INFECTED_PATIENT_DETAIL_FAILURE: 'GET_INFECTED_PATIENT_DETAIL_FAILURE',

  PUT_QUARANTINE_ADDRESS_REQUEST: 'PUT_QUARANTINE_ADDRESS_REQUEST',
  PUT_QUARANTINE_ADDRESS_SUCCESS: 'PUT_QUARANTINE_ADDRESS_SUCCESS',
  PUT_QUARANTINE_ADDRESS_FAILURE: 'PUT_QUARANTINE_ADDRESS_FAILURE',

  DELETE_INFECTED_PATIENT_REQUEST: 'DELETE_INFECTED_PATIENT_REQUEST',
  DELETE_INFECTED_PATIENT_SUCCESS: 'DELETE_INFECTED_PATIENT_SUCCESS',
  DELETE_INFECTED_PATIENT_FAILURE: 'DELETE_INFECTED_PATIENT_FAILURE',

  GET_DELETED_QUICK_TESTS_REQUEST: 'GET_DELETED_QUICK_TESTS_REQUEST',
  GET_DELETED_QUICK_TESTS_SUCCESS: 'GET_DELETED_QUICK_TESTS_SUCCESS',
  GET_DELETED_QUICK_TESTS_FAILURE: 'GET_DELETED_QUICK_TESTS_FAILURE',

  GET_QUICK_TESTS_BY_MANAGEMENT_UNIT_REQUEST: 'GET_QUICK_TESTS_BY_MANAGEMENT_UNIT_REQUEST',
  GET_QUICK_TESTS_BY_MANAGEMENT_UNIT_SUCCESS: 'GET_QUICK_TESTS_BY_MANAGEMENT_UNIT_SUCCESS',
  GET_QUICK_TESTS_BY_MANAGEMENT_UNIT_FAILURE: 'GET_QUICK_TESTS_BY_MANAGEMENT_UNIT_FAILURE',

  GET_QUICK_TESTS_BY_UNIT_TYPE_REQUEST: 'GET_QUICK_TESTS_BY_UNIT_TYPE_REQUEST',
  GET_QUICK_TESTS_BY_UNIT_TYPE_SUCCESS: 'GET_QUICK_TESTS_BY_UNIT_TYPE_SUCCESS',
  GET_QUICK_TESTS_BY_UNIT_TYPE_FAILURE: 'GET_QUICK_TESTS_BY_UNIT_TYPE_FAILURE',

  GET_POSITIVE_QUICK_TESTS_REQUEST: 'GET_POSITIVE_QUICK_TESTS_REQUEST',
  GET_POSITIVE_QUICK_TESTS_SUCCESS: 'GET_POSITIVE_QUICK_TESTS_SUCCESS',
  GET_POSITIVE_QUICK_TESTS_FAILURE: 'GET_POSITIVE_QUICK_TESTS_FAILURE',

  GET_PERSONAL_QUICK_TEST_HISTORY_REQUEST: 'GET_PERSONAL_QUICK_TEST_HISTORY_REQUEST',
  GET_PERSONAL_QUICK_TEST_HISTORY_SUCCESS: 'GET_PERSONAL_QUICK_TEST_HISTORY_SUCCESS',
  GET_PERSONAL_QUICK_TEST_HISTORY_FAILURE: 'GET_PERSONAL_QUICK_TEST_HISTORY_FAILURE',

  CREATE_QUICK_TEST_REQUEST: 'CREATE_QUICK_TEST_REQUEST',
  CREATE_QUICK_TEST_SUCCESS: 'CREATE_QUICK_TEST_SUCCESS',
  CREATE_QUICK_TEST_FAILURE: 'CREATE_QUICK_TEST_FAILURE',

  CREATE_QUICK_TEST_WITH_PROFILE_REQUEST: 'CREATE_QUICK_TEST_WITH_PROFILE_REQUEST',
  CREATE_QUICK_TEST_WITH_PROFILE_SUCCESS: 'CREATE_QUICK_TEST_WITH_PROFILE_SUCCESS',
  CREATE_QUICK_TEST_WITH_PROFILE_FAILURE: 'CREATE_QUICK_TEST_WITH_PROFILE_FAILURE',

  CREATE_BATCH_QUICK_TEST_REQUEST: 'CREATE_BATCH_QUICK_TEST_REQUEST',
  CREATE_BATCH_QUICK_TEST_SUCCESS: 'CREATE_BATCH_QUICK_TEST_SUCCESS',
  CREATE_BATCH_QUICK_TEST_FAILURE: 'CREATE_BATCH_QUICK_TEST_FAILURE',

  CREATE_BATCH_QUICK_TEST_WITH_PROFILE_REQUEST:
    'CREATE_BATCH_QUICK_TEST_WITH_PROFILE_REQUEST',
  CREATE_BATCH_QUICK_TEST_WITH_PROFILE_SUCCESS:
    'CREATE_BATCH_QUICK_TEST_WITH_PROFILE_SUCCESS',
  CREATE_BATCH_QUICK_TEST_WITH_PROFILE_FAILURE:
    'CREATE_BATCH_QUICK_TEST_WITH_PROFILE_FAILURE',

  UPDATE_QUICK_TEST_REQUEST: 'UPDATE_QUICK_TEST_REQUEST',
  UPDATE_QUICK_TEST_SUCCESS: 'UPDATE_QUICK_TEST_SUCCESS',
  UPDATE_QUICK_TEST_FAILURE: 'UPDATE_QUICK_TEST_FAILURE',

  UPDATE_QUICK_TEST_BY_ADMIN_REQUEST: 'UPDATE_QUICK_TEST_BY_ADMIN_REQUEST',
  UPDATE_QUICK_TEST_BY_ADMIN_SUCCESS: 'UPDATE_QUICK_TEST_BY_ADMIN_SUCCESS',
  UPDATE_QUICK_TEST_BY_ADMIN_FAILURE: 'UPDATE_QUICK_TEST_BY_ADMIN_FAILURE',

  DELETE_QUICK_TEST_REQUEST: 'DELETE_QUICK_TEST_REQUEST',
  DELETE_QUICK_TEST_SUCCESS: 'DELETE_QUICK_TEST_SUCCESS',
  DELETE_QUICK_TEST_FAILURE: 'DELETE_QUICK_TEST_FAILURE',

  DELETE_QUICK_TEST_BY_ADMIN_REQUEST: 'DELETE_QUICK_TEST_BY_ADMIN_REQUEST',
  DELETE_QUICK_TEST_BY_ADMIN_SUCCESS: 'DELETE_QUICK_TEST_BY_ADMIN_SUCCESS',
  DELETE_QUICK_TEST_BY_ADMIN_FAILURE: 'DELETE_QUICK_TEST_BY_ADMIN_FAILURE',

  RECOVERY_QUICK_TEST_REQUEST: 'RECOVERY_QUICK_TEST_REQUEST',
  RECOVERY_QUICK_TEST_SUCCESS: 'RECOVERY_QUICK_TEST_SUCCESS',
  RECOVERY_QUICK_TEST_FAILURE: 'RECOVERY_QUICK_TEST_FAILURE',

  CREATE_ASSIGN_QUICK_TEST_REQUEST: 'CREATE_ASSIGN_QUICK_TEST_REQUEST',
  CREATE_ASSIGN_QUICK_TEST_SUCCESS: 'CREATE_ASSIGN_QUICK_TEST_SUCCESS',
  CREATE_ASSIGN_QUICK_TEST_FAILURE: 'CREATE_ASSIGN_QUICK_TEST_FAILURE',

  PUBLISH_QUICK_TEST_REQUEST: 'PUBLISH_QUICK_TEST_REQUEST',
  PUBLISH_QUICK_TEST_SUCCESS: 'PUBLISH_QUICK_TEST_SUCCESS',
  PUBLISH_QUICK_TEST_FAILURE: 'PUBLISH_QUICK_TEST_FAILURE',

  ASSIGN_QUICK_TEST_SESSION: 'ASSIGN_QUICK_TEST_SESSION',

  CHANGE_PROFILE_QUICK_TEST_REQUEST: 'CHANGE_PROFILE_QUICK_TEST_REQUEST',
  CHANGE_PROFILE_QUICK_TEST_SUCCESS: 'CHANGE_PROFILE_QUICK_TEST_SUCCESS',
  CHANGE_PROFILE_QUICK_TEST_FAILURE: 'CHANGE_PROFILE_QUICK_TEST_FAILURE',

  IMPORT_QUICK_TEST_JSON_REQUEST: 'IMPORT_QUICK_TEST_JSON_REQUEST',
  IMPORT_QUICK_TEST_JSON_SUCCESS: 'IMPORT_QUICK_TEST_JSON_SUCCESS',
  IMPORT_QUICK_TEST_JSON_FAILURE: 'IMPORT_QUICK_TEST_JSON_FAILURE',

  CREATE_PCR_EXAMINATION_REQUEST: 'CREATE_PCR_EXAMINATION_REQUEST',
  CREATE_PCR_EXAMINATION_SUCCESS: 'CREATE_PCR_EXAMINATION_SUCCESS',
  CREATE_PCR_EXAMINATION_FAILURE: 'CREATE_PCR_EXAMINATION_FAILURE',
};
