export default {
  GET_CONTACT_LOCATIONS_REQUEST: 'GET_CONTACT_LOCATIONS_REQUEST',
  GET_CONTACT_LOCATIONS_SUCCESS: 'GET_CONTACT_LOCATIONS_SUCCESS',
  GET_CONTACT_LOCATIONS_FAILURE: 'GET_CONTACT_LOCATIONS_FAILURE',

  GET_CRITERIAS_REQUEST: 'GET_CRITERIAS_REQUEST',
  GET_CRITERIAS_SUCCESS: 'GET_CRITERIAS_SUCCESS',
  GET_CRITERIAS_FAILURE: 'GET_CRITERIAS_FAILURE',

  GET_CRITERIAS_BY_INFECTION_TYPE_REQUEST:
    'GET_CRITERIAS_BY_INFECTION_TYPE_REQUEST',
  GET_CRITERIAS_BY_INFECTION_TYPE_SUCCESS:
    'GET_CRITERIAS_BY_INFECTION_TYPE_SUCCESS',
  GET_CRITERIAS_BY_INFECTION_TYPE_FAILURE:
    'GET_CRITERIAS_BY_INFECTION_TYPE_FAILURE',

  GET_ASKING_REQUEST: 'GET_ASKING_REQUEST',
  GET_ASKING_SUCCESS: 'GET_ASKING_SUCCESS',
  GET_ASKING_FAILURE: 'GET_ASKING_FAILURE',

  UPDATE_ASKING_REQUEST: 'UPDATE_ASKING_REQUEST',
  UPDATE_ASKING_SUCCESS: 'UPDATE_ASKING_SUCCESS',
  UPDATE_ASKING_FAILURE: 'UPDATE_ASKING_FAILURE',

  CONCLUDE_ASKING_REQUEST: 'CONCLUDE_ASKING_REQUEST',
  CONCLUDE_ASKING_SUCCESS: 'CONCLUDE_ASKING_SUCCESS',
  CONCLUDE_ASKING_FAILURE: 'CONCLUDE_ASKING_FAILURE',

  CREATE_CONTACT_LOCATION_REQUEST: 'CREATE_CONTACT_LOCATION_REQUEST',
  CREATE_CONTACT_LOCATION_SUCCESS: 'CREATE_CONTACT_LOCATION_SUCCESS',
  CREATE_CONTACT_LOCATION_FAILURE: 'CREATE_CONTACT_LOCATION_FAILURE',

  UPDATE_CONTACT_LOCATION_REQUEST: 'UPDATE_CONTACT_LOCATION_REQUEST',
  UPDATE_CONTACT_LOCATION_SUCCESS: 'UPDATE_CONTACT_LOCATION_SUCCESS',
  UPDATE_CONTACT_LOCATION_FAILURE: 'UPDATE_CONTACT_LOCATION_FAILURE',

  DELETE_CONTACT_LOCATION_REQUEST: 'DELETE_CONTACT_LOCATION_REQUEST',
  DELETE_CONTACT_LOCATION_SUCCESS: 'DELETE_CONTACT_LOCATION_SUCCESS',
  DELETE_CONTACT_LOCATION_FAILURE: 'DELETE_CONTACT_LOCATION_FAILURE',

  GET_CONTACT_VEHICLES_REQUEST: 'GET_CONTACT_VEHICLES_REQUEST',
  GET_CONTACT_VEHICLES_SUCCESS: 'GET_CONTACT_VEHICLES_SUCCESS',
  GET_CONTACT_VEHICLES_FAILURE: 'GET_CONTACT_VEHICLES_FAILURE',

  CREATE_CONTACT_VEHICLE_REQUEST: 'CREATE_CONTACT_VEHICLE_REQUEST',
  CREATE_CONTACT_VEHICLE_SUCCESS: 'CREATE_CONTACT_VEHICLE_SUCCESS',
  CREATE_CONTACT_VEHICLE_FAILURE: 'CREATE_CONTACT_VEHICLE_FAILURE',

  UPDATE_CONTACT_VEHICLE_REQUEST: 'UPDATE_CONTACT_VEHICLE_REQUEST',
  UPDATE_CONTACT_VEHICLE_SUCCESS: 'UPDATE_CONTACT_VEHICLE_SUCCESS',
  UPDATE_CONTACT_VEHICLE_FAILURE: 'UPDATE_CONTACT_VEHICLE_FAILURE',

  DELETE_CONTACT_VEHICLE_REQUEST: 'DELETE_CONTACT_VEHICLE_REQUEST',
  DELETE_CONTACT_VEHICLE_SUCCESS: 'DELETE_CONTACT_VEHICLE_SUCCESS',
  DELETE_CONTACT_VEHICLE_FAILURE: 'DELETE_CONTACT_VEHICLE_FAILURE',

  GET_ASKING_RESULT_REQUEST: 'GET_ASKING_RESULT_REQUEST',
  GET_ASKING_RESULT_SUCCESS: 'GET_ASKING_RESULT_SUCCESS',
  GET_ASKING_RESULT_FAILURE: 'GET_ASKING_RESULT_FAILURE',

  SEARCH_LOCATION_REQUEST: 'SEARCH_LOCATION_REQUEST',
  SEARCH_LOCATION_SUCCESS: 'SEARCH_LOCATION_SUCCESS',
  SEARCH_LOCATION_FAILURE: 'SEARCH_LOCATION_FAILURE',

  SEARCH_AIRPLANE_REQUEST: 'SEARCH_AIRPLANE_REQUEST',
  SEARCH_AIRPLANE_SUCCESS: 'SEARCH_AIRPLANE_SUCCESS',
  SEARCH_AIRPLANE_FAILURE: 'SEARCH_AIRPLANE_FAILURE',

  SEARCH_OTHER_VEHICLE_REQUEST: 'SEARCH_OTHER_VEHICLE_REQUEST',
  SEARCH_OTHER_VEHICLE_SUCCESS: 'SEARCH_OTHER_VEHICLE_SUCCESS',
  SEARCH_OTHER_VEHICLE_FAILURE: 'SEARCH_OTHER_VEHICLE_FAILURE',

  GET_OUTBREAK_LOCATION_REQUEST: 'GET_OUTBREAK_LOCATION_REQUEST',
  GET_OUTBREAK_LOCATION_SUCCESS: 'GET_OUTBREAK_LOCATION_SUCCESS',
  GET_OUTBREAK_LOCATION_FAILURE: 'GET_OUTBREAK_LOCATION_FAILURE',

  UPDATE_ESTATE_REQUEST: 'UPDATE_ESTATE_REQUEST',
  UPDATE_ESTATE_SUCCESS: 'UPDATE_ESTATE_SUCCESS',
  UPDATE_ESTATE_FAILURE: 'UPDATE_ESTATE_FAILURE',

  CLEAR_ASKING: 'CLEAR_ASKING',

  SELECT_ESTATE: 'SELECT_ESTATE',
  SELECT_AIRPLANE: 'SELECT_AIRPLANE',
  SELECT_VEHICLE: 'SELECT_VEHICLE',

  GET_LOCATION_VISITORS_REQUEST: 'GET_LOCATION_VISITORS_REQUEST',
  GET_LOCATION_VISITORS_SUCCESS: 'GET_LOCATION_VISITORS_SUCCESS',
  GET_LOCATION_VISITORS_FAILURE: 'GET_LOCATION_VISITORS_FAILURE',

  ADD_LOCATION_VISITORS_REQUEST: 'ADD_LOCATION_VISITORS_REQUEST',
  ADD_LOCATION_VISITORS_SUCCESS: 'ADD_LOCATION_VISITORS_SUCCESS',
  ADD_LOCATION_VISITORS_FAILURE: 'ADD_LOCATION_VISITORS_FAILURE',

  REMOVE_LOCATION_VISITOR_REQUEST: 'REMOVE_LOCATION_VISITOR_REQUEST',
  REMOVE_LOCATION_VISITOR_SUCCESS: 'REMOVE_LOCATION_VISITOR_SUCCESS',
  REMOVE_LOCATION_VISITOR_FAILURE: 'REMOVE_LOCATION_VISITOR_FAILURE',

  GET_PROFILE_LIST_REQUEST: 'GET_PROFILE_LIST_REQUEST',
  GET_PROFILE_LIST_SUCCESS: 'GET_PROFILE_LIST_SUCCESS',
  GET_PROFILE_LIST_FAILURE: 'GET_PROFILE_LIST_FAILURE',

  CREATE_LOCATION_REQUEST: 'CREATE_LOCATION_REQUEST',
  CREATE_LOCATION_SUCCESS: 'CREATE_LOCATION_SUCCESS',
  CREATE_LOCATION_FAILURE: 'CREATE_LOCATION_FAILURE',
};
