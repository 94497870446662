import FacilityListTable from 'quarantine-facilities/components/facilities/FacilityListTable';
import React from 'react';

const FacilityListPage = (props) => {
  return (
    <div>
      <FacilityListTable />
    </div>
  );
};

export default FacilityListPage;
