export const GET_SUBJECT_TYPE_NUMBER_REQUEST =
  'GET_SUBJECT_TYPE_NUMBER_REQUEST';
export const GET_SUBJECT_TYPE_NUMBER_SUCCESS =
  'GET_SUBJECT_TYPE_NUMBER_SUCCESS';
export const GET_SUBJECT_TYPE_NUMBER_FAILURE =
  'GET_SUBJECT_TYPE_NUMBER_FAILURE';

export const GET_SUBJECT_TYPES_NUMBER_REQUEST =
  'GET_SUBJECT_TYPES_NUMBER_REQUEST';
export const GET_SUBJECT_TYPES_NUMBER_SUCCESS =
  'GET_SUBJECT_TYPES_NUMBER_SUCCESS';
export const GET_SUBJECT_TYPES_NUMBER_FAILURE =
  'GET_SUBJECT_TYPES_NUMBER_FAILURE';

export const GET_SUBJECT_SUMMARIES_REQUEST = 'GET_SUBJECT_SUMMARIES_REQUEST';
export const GET_SUBJECT_SUMMARIES_SUCCESS = 'GET_SUBJECT_SUMMARIES_SUCCESS';
export const GET_SUBJECT_SUMMARIES_FAILURE = 'GET_SUBJECT_SUMMARIES_FAILURE';

export const GET_SUBJECT_SUMMARY_BY_RANGE_REQUEST =
  'GET_SUBJECT_SUMMARY_BY_RANGE_REQUEST';
export const GET_SUBJECT_SUMMARY_BY_RANGE_SUCCESS =
  'GET_SUBJECT_SUMMARY_BY_RANGE_SUCCESS';
export const GET_SUBJECT_SUMMARY_BY_RANGE_FAILURE =
  'GET_SUBJECT_SUMMARY_BY_RANGE_FAILURE';

export const GET_EXAMINATION_SUMMARY_REQUEST =
  'GET_EXAMINATION_SUMMARY_REQUEST';
export const GET_EXAMINATION_SUMMARY_SUCCESS =
  'GET_EXAMINATION_SUMMARY_SUCCESS';
export const GET_EXAMINATION_SUMMARY_FAILURE =
  'GET_EXAMINATION_SUMMARY_FAILURE';

export const GET_EXAMINATION_SUMMARY_BY_RANGE_REQUEST =
  'GET_EXAMINATION_SUMMARY_BY_RANGE_REQUEST';
export const GET_EXAMINATION_SUMMARY_BY_RANGE_SUCCESS =
  'GET_EXAMINATION_SUMMARY_BY_RANGE_SUCCESS';
export const GET_EXAMINATION_SUMMARY_BY_RANGE_FAILURE =
  'GET_EXAMINATION_SUMMARY_BY_RANGE_FAILURE';

export const SELECT_SUBJECT_SUMMARIES_LIST = 'SELECT_SUBJECT_SUMMARIES_LIST';
export const SELECT_SUBJECT_TYPE = 'SELECT_SUBJECT_TYPE';
export const SELECT_SUBJECT_LOCATION = 'SELECT_SUBJECT_LOCATION';
export const SELECT_LOCATION_TYPE = 'SELECT_LOCATION_TYPE';

export default {
  EXAMINATION_GET_TAKEN_EXAMINATION_COUNT_BY_DATE_REQUEST:
    'EXAMINATION_GET_TAKEN_EXAMINATION_COUNT_BY_DATE_REQUEST',
  EXAMINATION_GET_TAKEN_EXAMINATION_COUNT_BY_DATE_SUCCESS:
    'EXAMINATION_GET_TAKEN_EXAMINATION_COUNT_BY_DATE_SUCCESS',
  EXAMINATION_GET_TAKEN_EXAMINATION_COUNT_BY_DATE_FAILURE:
    'EXAMINATION_GET_TAKEN_EXAMINATION_COUNT_BY_DATE_FAILURE',

  EXAMINATION_GET_RESULT_EXAMINATION_COUNT_REQUEST:
    'EXAMINATION_GET_RESULT_EXAMINATION_COUNT_REQUEST',
  EXAMINATION_GET_RESULT_EXAMINATION_COUNT_SUCCESS:
    'EXAMINATION_GET_RESULT_EXAMINATION_COUNT_SUCCESS',
  EXAMINATION_GET_RESULT_EXAMINATION_COUNT_FAILURE:
    'EXAMINATION_GET_RESULT_EXAMINATION_COUNT_FAILURE',

  EXAMINATION_GET_PEOPLE_ASSIGNED_COUNT_REQUEST:
    'EXAMINATION_GET_PEOPLE_ASSIGNED_COUNT_REQUEST',
  EXAMINATION_GET_PEOPLE_ASSIGNED_COUNT_SUCCESS:
    'EXAMINATION_GET_PEOPLE_ASSIGNED_COUNT_SUCCESS',
  EXAMINATION_GET_PEOPLE_ASSIGNED_COUNT_FAILURE:
    'EXAMINATION_GET_PEOPLE_ASSIGNED_COUNT_FAILURE',

  EXAMINATION_GET_PEOPLE_TAKEN_EXAM_COUNT_REQUEST:
    'EXAMINATION_GET_PEOPLE_TAKEN_EXAM_COUNT_REQUEST',
  EXAMINATION_GET_PEOPLE_TAKEN_EXAM_COUNT_SUCCESS:
    'EXAMINATION_GET_PEOPLE_TAKEN_EXAM_COUNT_SUCCESS',
  EXAMINATION_GET_PEOPLE_TAKEN_EXAM_COUNT_FAILURE:
    'EXAMINATION_GET_PEOPLE_TAKEN_EXAM_COUNT_FAILURE',

  EXAMINATION_GET_PEOPLE_HAS_RESULT_EXAM_COUNT_REQUEST:
    'EXAMINATION_GET_PEOPLE_HAS_RESULT_EXAM_COUNT_REQUEST',
  EXAMINATION_GET_PEOPLE_HAS_RESULT_EXAM_COUNT_SUCCESS:
    'EXAMINATION_GET_PEOPLE_HAS_RESULT_EXAM_COUNT_SUCCESS',
  EXAMINATION_GET_PEOPLE_HAS_RESULT_EXAM_COUNT_FAILURE:
    'EXAMINATION_GET_PEOPLE_HAS_RESULT_EXAM_COUNT_FAILURE',

  EXAMINATION_GET_EXAM_WAITING_RESULT_COUNT_REQUEST:
    'EXAMINATION_GET_EXAM_WAITING_RESULT_COUNT_REQUEST',
  EXAMINATION_GET_EXAM_WAITING_RESULT_COUNT_SUCCESS:
    'EXAMINATION_GET_EXAM_WAITING_RESULT_COUNT_SUCCESS',
  EXAMINATION_GET_EXAM_WAITING_RESULT_COUNT_FAILURE:
    'EXAMINATION_GET_EXAM_WAITING_RESULT_COUNT_FAILURE',

  GET_PEOPLE_EXAMINATION_STATISTIC_REQUEST:
    'GET_PEOPLE_EXAMINATION_STATISTIC_REQUEST',
  GET_PEOPLE_EXAMINATION_STATISTIC_SUCCESS:
    'GET_PEOPLE_EXAMINATION_STATISTIC_SUCCESS',
  GET_PEOPLE_EXAMINATION_STATISTIC_FAILURE:
    'GET_PEOPLE_EXAMINATION_STATISTIC_FAILURE',

  GET_EXAMINATION_DETAIL_STATISTIC_REQUEST:
    'GET_EXAMINATION_DETAIL_STATISTIC_REQUEST',
  GET_EXAMINATION_DETAIL_STATISTIC_SUCCESS:
    'GET_EXAMINATION_DETAIL_STATISTIC_SUCCESS',
  GET_EXAMINATION_DETAIL_STATISTIC_FAILURE:
    'GET_EXAMINATION_DETAIL_STATISTIC_FAILURE',

  GET_GROUPED_EXAM_DETAIL_STATISTIC_REQUEST:
    'GET_GROUPED_EXAM_DETAIL_STATISTIC_REQUEST',
  GET_GROUPED_EXAM_DETAIL_STATISTIC_SUCCESS:
    'GET_GROUPED_EXAM_DETAIL_STATISTIC_SUCCESS',
  GET_GROUPED_EXAM_DETAIL_STATISTIC_FAILURE:
    'GET_GROUPED_EXAM_DETAIL_STATISTIC_FAILURE',

  GET_DASHBOARD_BY_DATE_REQUEST: 'GET_DASHBOARD_BY_DATE_REQUEST',
  GET_DASHBOARD_BY_DATE_SUCCESS: 'GET_DASHBOARD_BY_DATE_SUCCESS',
  GET_DASHBOARD_BY_DATE_FAILURE: 'GET_DASHBOARD_BY_DATE_FAILURE',

  GET_CHAINS_SUMMARIES_REQUEST: 'GET_CHAINS_SUMMARIES_REQUEST',
  GET_CHAINS_SUMMARIES_SUCCESS: 'GET_CHAINS_SUMMARIES_SUCCESS',
  GET_CHAINS_SUMMARIES_FAILURE: 'GET_CHAINS_SUMMARIES_FAILURE',

  RELOAD_SUMMARIES_REQUEST: 'RELOAD_SUMMARIES_REQUEST',
  RELOAD_SUMMARIES_SUCCESS: 'RELOAD_SUMMARIES_SUCCESS',
  RELOAD_SUMMARIES_FAILURE: 'RELOAD_SUMMARIES_FAILURE',

  GET_CHAINS_PREVIOUS_DATES_SUMMARIES_REQUEST:
    'GET_CHAINS_PREVIOUS_DATES_SUMMARIES_REQUEST',
  GET_CHAINS_PREVIOUS_DATES_SUMMARIES_SUCCESS:
    'GET_CHAINS_PREVIOUS_DATES_SUMMARIES_SUCCESS',
  GET_CHAINS_PREVIOUS_DATES_SUMMARIES_FAILURE:
    'GET_CHAINS_PREVIOUS_DATES_SUMMARIES_FAILURE',
};
