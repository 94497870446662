import React from 'react';
import FacilityTable from 'treatment/components/facility/FacilityTable';

const FacilityPage = (props) => {
  return (
    <div>
      <FacilityTable />
    </div>
  );
};

export default FacilityPage;
