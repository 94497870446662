import React from 'react';
import GeneralListTable from 'general/components/GeneralListTable';

const GeneralListPage = (props) => {
  return (
    <div>
      <GeneralListTable />
    </div>
  );
};

export default GeneralListPage;
