import React from 'react';
import CollectingSessionTable from '../components/CollectingSessionTable';

const CollectingSessionPage = (props) => {
  return (
    <div>
      <CollectingSessionTable />
    </div>
  );
};

export default CollectingSessionPage;
