export default {
  SELECT_QUARANTINE: 'SELECT_QUARANTINE',

  TOGGLE_CREATE_MODAL: 'TOGGLE_CREATE_MODAL',
  TOGGLE_EDIT_MODAL: 'TOGGLE_EDIT_MODAL',

  GET_QUARANTINES_REQUEST: 'GET_QUARANTINES_REQUEST',
  GET_QUARANTINES_SUCCESS: 'GET_QUARANTINES_SUCCESS',
  GET_QUARANTINES_FAILURE: 'GET_QUARANTINES_FAILURE',

  GET_COMPLETED_SUBJECTS_REQUEST: 'GET_COMPLETED_SUBJECTS_REQUEST',
  GET_COMPLETED_SUBJECTS_SUCCESS: 'GET_COMPLETED_SUBJECTS_SUCCESS',
  GET_COMPLETED_SUBJECTS_FAILURE: 'GET_COMPLETED_SUBJECTS_FAILURE',

  GET_WAITING_LIST_REQUEST: 'GET_WAITING_LIST_REQUEST',
  GET_WAITING_LIST_SUCCESS: 'GET_WAITING_LIST_SUCCESS',
  GET_WAITING_LIST_FAILURE: 'GET_WAITING_LIST_FAILURE',

  GET_QUARANTINE_REQUEST: 'GET_QUARANTINE_REQUEST',
  GET_QUARANTINE_SUCCESS: 'GET_QUARANTINE_SUCCESS',
  GET_QUARANTINE_FAILURE: 'GET_QUARANTINE_FAILURE',

  CREATE_QUARANTINE_REQUEST: 'CREATE_QUARANTINE_REQUEST',
  CREATE_QUARANTINE_SUCCESS: 'CREATE_QUARANTINE_SUCCESS',
  CREATE_QUARANTINE_FAILURE: 'CREATE_QUARANTINE_FAILURE',

  UPDATE_QUARANTINE_REQUEST: 'UPDATE_QUARANTINE_REQUEST',
  UPDATE_QUARANTINE_SUCCESS: 'UPDATE_QUARANTINE_SUCCESS',
  UPDATE_QUARANTINE_FAILURE: 'UPDATE_QUARANTINE_FAILURE',

  DELETE_QUARANTINE_REQUEST: 'DELETE_QUARANTINE_REQUEST',
  DELETE_QUARANTINE_SUCCESS: 'DELETE_QUARANTINE_SUCCESS',
  DELETE_QUARANTINE_FAILURE: 'DELETE_QUARANTINE_FAILURE',

  SELECT_ZONE: 'SELECT_ZONE',
  SELECT_ROOM: 'SELECT_ROOM',

  OPEN_ZONE_DETAIL: 'OPEN_ZONE_DETAIL',
  OPEN_ROOM_DETAIL: 'OPEN_ROOM_DETAIL',
  OPEN_WAITING_SUBJECT: 'OPEN_WAITING_SUBJECT',

  GET_ZONES_REQUEST: 'GET_ZONES_REQUEST',
  GET_ZONES_SUCCESS: 'GET_ZONES_SUCCESS',
  GET_ZONES_FAILURE: 'GET_ZONES_FAILURE',

  CREATE_ZONE_REQUEST: 'CREATE_ZONE_REQUEST',
  CREATE_ZONE_SUCCESS: 'CREATE_ZONE_SUCCESS',
  CREATE_ZONE_FAILURE: 'CREATE_ZONE_FAILURE',

  UPDATE_ZONE_REQUEST: 'UPDATE_ZONE_REQUEST',
  UPDATE_ZONE_SUCCESS: 'UPDATE_ZONE_SUCCESS',
  UPDATE_ZONE_FAILURE: 'UPDATE_ZONE_FAILURE',

  DELETE_ZONE_REQUEST: 'DELETE_ZONE_REQUEST',
  DELETE_ZONE_SUCCESS: 'DELETE_ZONE_SUCCESS',
  DELETE_ZONE_FAILURE: 'DELETE_ZONE_FAILURE',

  GET_ROOMS_REQUEST: 'GET_ROOMS_REQUEST',
  GET_ROOMS_SUCCESS: 'GET_ROOMS_SUCCESS',
  GET_ROOMS_FAILURE: 'GET_ROOMS_FAILURE',

  CREATE_ROOM_REQUEST: 'CREATE_ROOM_REQUEST',
  CREATE_ROOM_SUCCESS: 'CREATE_ROOM_SUCCESS',
  CREATE_ROOM_FAILURE: 'CREATE_ROOM_FAILURE',

  UPDATE_ROOM_REQUEST: 'UPDATE_ROOM_REQUEST',
  UPDATE_ROOM_SUCCESS: 'UPDATE_ROOM_SUCCESS',
  UPDATE_ROOM_FAILURE: 'UPDATE_ROOM_FAILURE',

  DELETE_ROOM_REQUEST: 'DELETE_ROOM_REQUEST',
  DELETE_ROOM_SUCCESS: 'DELETE_ROOM_SUCCESS',
  DELETE_ROOM_FAILURE: 'DELETE_ROOM_FAILURE',

  MOVE_QUARANTINE_REQUEST: 'MOVE_QUARANTINE_REQUEST',
  MOVE_QUARANTINE_SUCCESS: 'MOVE_QUARANTINE_SUCCESS',
  MOVE_QUARANTINE_FAILURE: 'MOVE_QUARANTINE_FAILURE',

  MOVE_ROOM_REQUEST: 'MOVE_ROOM_REQUEST',
  MOVE_ROOM_SUCCESS: 'MOVE_ROOM_SUCCESS',
  MOVE_ROOM_FAILURE: 'MOVE_ROOM_FAILURE',

  COMPLETE_QUARANTINE_REQUEST: 'COMPLETE_QUARANTINE_REQUEST',
  COMPLETE_QUARANTINE_SUCCESS: 'COMPLETE_QUARANTINE_SUCCESS',
  COMPLETE_QUARANTINE_FAILURE: 'COMPLETE_QUARANTINE_FAILURE',

  EXTEND_DURATION_REQUEST: 'EXTEND_DURATION_REQUEST',
  EXTEND_DURATION_SUCCESS: 'EXTEND_DURATION_SUCCESS',
  EXTEND_DURATION_FAILURE: 'EXTEND_DURATION_FAILURE',

  GET_AVAILABLE_ROOMS_REQUEST: 'GET_AVAILABLE_ROOMS_REQUEST',
  GET_AVAILABLE_ROOMS_SUCCESS: 'GET_AVAILABLE_ROOMS_SUCCESS',
  GET_AVAILABLE_ROOMS_FAILURE: 'GET_AVAILABLE_ROOMS_FAILURE',

  GET_SUBJECT_IN_ROOM_REQUEST: 'GET_SUBJECT_IN_ROOM_REQUEST',
  GET_SUBJECT_IN_ROOM_SUCCESS: 'GET_SUBJECT_IN_ROOM_SUCCESS',
  GET_SUBJECT_IN_ROOM_FAILURE: 'GET_SUBJECT_IN_ROOM_FAILURE',

  TAKE_IN_REQUEST: 'TAKE_IN_REQUEST',
  TAKE_IN_SUCCESS: 'TAKE_IN_SUCCESS',
  TAKE_IN_FAILURE: 'TAKE_IN_FAILURE',

  EDIT_HISTORY_REQUEST: 'EDIT_HISTORY_REQUEST',
  EDIT_HISTORY_SUCCESS: 'EDIT_HISTORY_SUCCESS',
  EDIT_HISTORY_FAILURE: 'EDIT_HISTORY_FAILURE',

  GET_ALL_QUARANTINE_ZONES_REQUEST: 'GET_ALL_QUARANTINE_ZONES_REQUEST',
  GET_ALL_QUARANTINE_ZONES_SUCCESS: 'GET_ALL_QUARANTINE_ZONES_SUCCESS',
  GET_ALL_QUARANTINE_ZONES_FAILURE: 'GET_ALL_QUARANTINE_ZONES_FAILURE',

  GET_QUARANTINE_SUBJECTS_REQUEST: 'GET_QUARANTINE_SUBJECTS_REQUEST',
  GET_QUARANTINE_SUBJECTS_SUCCESS: 'GET_QUARANTINE_SUBJECTS_SUCCESS',
  GET_QUARANTINE_SUBJECTS_FAILURE: 'GET_QUARANTINE_SUBJECTS_FAILURE',

  GET_QUARANTINE_WAITING_SUBJECTS_REQUEST:
    'GET_QUARANTINE_WAITING_SUBJECTS_REQUEST',
  GET_QUARANTINE_WAITING_SUBJECTS_SUCCESS:
    'GET_QUARANTINE_WAITING_SUBJECTS_SUCCESS',
  GET_QUARANTINE_WAITING_SUBJECTS_FAILURE:
    'GET_QUARANTINE_WAITING_SUBJECTS_FAILURE',

  GET_FACILITY_REQUEST: 'GET_FACILITY_REQUEST',
  GET_FACILITY_SUCCESS: 'GET_FACILITY_SUCCESS',
  GET_FACILITY_FAILURE: 'GET_FACILITY_FAILURE',

  CREATE_FACILITY_REQUEST: 'CREATE_FACILITY_REQUEST',
  CREATE_FACILITY_SUCCESS: 'CREATE_FACILITY_SUCCESS',
  CREATE_FACILITY_FAILURE: 'CREATE_FACILITY_FAILURE',

  UPDATE_FACILITY_REQUEST: 'UPDATE_FACILITY_REQUEST',
  UPDATE_FACILITY_SUCCESS: 'UPDATE_FACILITY_SUCCESS',
  UPDATE_FACILITY_FAILURE: 'UPDATE_FACILITY_FAILURE',

  DELETE_FACILITY_REQUEST: 'DELETE_FACILITY_REQUEST',
  DELETE_FACILITY_SUCCESS: 'DELETE_FACILITY_SUCCESS',
  DELETE_FACILITY_FAILURE: 'DELETE_FACILITY_FAILURE',

  SET_FACILITY_MANAGER_REQUEST: 'SET_FACILITY_MANAGER_REQUEST',
  SET_FACILITY_MANAGER_SUCCESS: 'SET_FACILITY_MANAGER_SUCCESS',
  SET_FACILITY_MANAGER_FAILURE: 'SET_FACILITY_MANAGER_FAILURE',

  GET_QUARANTINE_REQUEST_REQUEST: 'GET_QUARANTINE_REQUEST_REQUEST',
  GET_QUARANTINE_REQUEST_SUCCESS: 'GET_QUARANTINE_REQUEST_SUCCESS',
  GET_QUARANTINE_REQUEST_FAILURE: 'GET_QUARANTINE_REQUEST_FAILURE',

  CREATE_QUARANTINE_REQUEST_REQUEST: 'CREATE_QUARANTINE_REQUEST_REQUEST',
  CREATE_QUARANTINE_REQUEST_SUCCESS: 'CREATE_QUARANTINE_REQUEST_SUCCESS',
  CREATE_QUARANTINE_REQUEST_FAILURE: 'CREATE_QUARANTINE_REQUEST_FAILURE',

  UPDATE_QUARANTINE_REQUEST_REQUEST: 'UPDATE_QUARANTINE_REQUEST_REQUEST',
  UPDATE_QUARANTINE_REQUEST_SUCCESS: 'UPDATE_QUARANTINE_REQUEST_SUCCESS',
  UPDATE_QUARANTINE_REQUEST_FAILURE: 'UPDATE_QUARANTINE_REQUEST_FAILURE',

  DELETE_QUARANTINE_REQUEST_REQUEST: 'DELETE_QUARANTINE_REQUEST_REQUEST',
  DELETE_QUARANTINE_REQUEST_SUCCESS: 'DELETE_QUARANTINE_REQUEST_SUCCESS',
  DELETE_QUARANTINE_REQUEST_FAILURE: 'DELETE_QUARANTINE_REQUEST_FAILURE',

  GET_QUARANTINE_REQUEST_DETAIL_REQUEST:
    'GET_QUARANTINE_REQUEST_DETAIL_REQUEST',
  GET_QUARANTINE_REQUEST_DETAIL_SUCCESS:
    'GET_QUARANTINE_REQUEST_DETAIL_SUCCESS',
  GET_QUARANTINE_REQUEST_DETAIL_FAILURE:
    'GET_QUARANTINE_REQUEST_DETAIL_FAILURE',

  CREATE_PROFILE_FROM_QUARANTINE_REQUEST:
    'CREATE_PROFILE_FROM_QUARANTINE_REQUEST',
  CREATE_PROFILE_FROM_QUARANTINE_SUCCESS:
    'CREATE_PROFILE_FROM_QUARANTINE_SUCCESS',
  CREATE_PROFILE_FROM_QUARANTINE_FAILURE:
    'CREATE_PROFILE_FROM_QUARANTINE_FAILURE',

  TM_UNDO_REQUEST: 'TM_UNDO_REQUEST',
  TM_UNDO_SUCCESS: 'TM_UNDO_SUCCESS',
  TM_UNDO_FAILURE: 'TM_UNDO_FAILURE',
};
