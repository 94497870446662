// auth
export const LOG_IN_REQUEST = 'LOG_IN_REQUEST';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';

export const GET_PERMISSION_REQUEST = 'GET_PERMISSION_REQUEST';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_FAILURE = 'GET_PERMISSION_FAILURE';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';

export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';

export const LOG_OUT = 'LOG_OUT';

// global
export const SHOW_CONFIRM_MODAL = 'SHOW_CONFIRM_MODAL';
export const SHOW_FORWARD_MODAL = 'SHOW_FORWARD_MODAL';
export const SHOW_INFO_MODAL = 'SHOW_INFO_MODAL';
export const SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL';

export const TRIGGER_SIDEBAR_OPEN = 'TRIGGER_SIDEBAR_OPEN';
export const TRIGGER_TREE_FOLDER_OPEN = 'TRIGGER_TREE_FOLDER_OPEN';

export default {
  IMPORT_REQUEST: 'IMPORT_REQUEST',
  IMPORT_SUCCESS: 'IMPORT_SUCCESS',
  IMPORT_FAILURE: 'IMPORT_FAILURE',

  EXPORT_REQUEST: 'EXPORT_REQUEST',
  EXPORT_SUCCESS: 'EXPORT_SUCCESS',
  EXPORT_FAILURE: 'EXPORT_FAILURE',

  AUTH_CHANGE_PASSWORD_REQUEST: 'AUTH_CHANGE_PASSWORD_REQUEST',
  AUTH_CHANGE_PASSWORD_SUCCESS: 'AUTH_CHANGE_PASSWORD_SUCCESS',
  AUTH_CHANGE_PASSWORD_FAILURE: 'AUTH_CHANGE_PASSWORD_FAILURE',
};
