import QuarantineStatisticSheet1 from 'quarantine-facilities/components/statistic/QuarantineStatisticSheet1';
import QuarantineStatisticSheet2 from 'quarantine-facilities/components/statistic/QuarantineStatisticSheet2';
import QuarantineStatisticSheet3 from 'quarantine-facilities/components/statistic/QuarantineStatisticSheet3';
import QuarantineStatisticSheet4 from 'quarantine-facilities/components/statistic/QuarantineStatisticSheet4';
import QuarantineStatisticSheet5 from 'quarantine-facilities/components/statistic/QuarantineStatisticSheet5';
import QuarantineStatisticSheet6 from 'quarantine-facilities/components/statistic/QuarantineStatisticSheet6';
import QuarantineStatisticSheet7 from 'quarantine-facilities/components/statistic/QuarantineStatisticSheet7';
import QuarantineStatisticSheet8 from 'quarantine-facilities/components/statistic/QuarantineStatisticSheet8';
import QuarantineStatisticSheet9 from 'quarantine-facilities/components/statistic/QuarantineStatisticSheet9';

export default {
  QuarantineStatisticSheet1,
  QuarantineStatisticSheet2,
  QuarantineStatisticSheet3,
  QuarantineStatisticSheet4,
  QuarantineStatisticSheet5,
  QuarantineStatisticSheet6,
  QuarantineStatisticSheet7,
  QuarantineStatisticSheet8,
  QuarantineStatisticSheet9,
};
