import React from "react";
import styled from "styled-components";

import { useAuth } from "app/hooks";
import { Menu, Dropdown, Icon, Image } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import useWindowSize from "app/hooks/use-window-size";

const StyledMenuItem = styled(Menu.Item)`
  padding: 0 !important;
  padding-right: 20px !important;
  span {
    font-size: 43px;
    font-weight: bold;
  }
`;
const StyledDropdown = styled(Dropdown)`
  div,
  i {
    color: black !important;
  }
`;

const MenuItems = () => {
  const history = useHistory();
  const { isAdmin, isHcdcDtr } = useAuth();
  const { isIpadSize } = useWindowSize();

  return (
    <>
      <StyledMenuItem
        header
        content={
          <>
            <Image
              style={{ paddingRight: "5px" }}
              size="mini"
              src="https://londonvetspecialists.vet/wp-content/uploads/2020/04/covid-icon.png"
              verticalAlign="bottom"
            />
            <span>CDS</span>
          </>
        }
        onClick={() => history.push("/home")}
      />
      {/* <Menu.Item as={Link} to="/dashboard" content="Dashboard" /> */}
      <Dropdown.Item as={Link} to="/profile" content="Quản lý hồ sơ" />
      <Dropdown item simple text="Điều tra">
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/chain" content="Chuỗi" />
          <StyledDropdown item text="Cá nhân">
            <Dropdown.Menu className={`${isIpadSize ? "sub-menu" : ""}`}>
              <Dropdown.Item
                as={Link}
                to="/subject"
                content="Danh sách đã xác minh"
                icon={isIpadSize && <Icon name="circle" />}
              />
              <Dropdown.Item
                as={Link}
                to="/unverified-subject"
                content="Danh sách chưa xác minh"
                icon={isIpadSize && <Icon name="circle" />}
              />
            </Dropdown.Menu>
          </StyledDropdown>
          <Dropdown.Item as={Link} to="/estate" content="Địa điểm" />
          <Dropdown.Item as={Link} to="/airplane" content="Máy bay" />
          <Dropdown.Item as={Link} to="/vehicle" content="Phương tiện khác" />
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown item simple text="Xét nghiệm">
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/unit" content="Quản lý cơ sở" />
          <Dropdown.Item
            as={Link}
            to="/quick-test"
            content="Quản lý test nhanh"
          />
          <Dropdown.Item as={Link} to="/code" content="Mã xét nghiệm" />
          <Dropdown.Item as={Link} to="/examination" content="Quản lý mẫu" />
          <Dropdown.Item
            as={Link}
            to="/assign"
            content="Quản lý phiên lấy mẫu"
          />
          <Dropdown.Item
            as={Link}
            to="/transport"
            content="Quản lý chuyển mẫu"
          />
          <Dropdown.Item
            as={Link}
            to="/test-session"
            content="Quản lý phiên xét nghiệm"
          />
          {/* <Dropdown.Item
            as={Link}
            to="/collecting-session"
            content="Quản lý buổi lấy mẫu"
          /> */}
        </Dropdown.Menu>
      </Dropdown>
      {/* <Menu.Item as={Link} to="/infectious-disease" content="Ca bệnh" />
      <Dropdown item simple text="Cách ly tập trung">
        <Dropdown.Menu>
          {isAdmin ? (
            <Dropdown.Item
              as={Link}
              to="/facilities"
              content="Danh sách khu cách ly"
            />
          ) : (
            <Dropdown.Item
              as={Link}
              to="/facility-info"
              content="Thông tin khu cách ly"
            />
          )}
          <Dropdown.Item
            as={Link}
            to="/facilities-waiting-list"
            content="Danh sách chờ cách ly"
          />
          <Dropdown.Item
            as={Link}
            to="/facilities-in-quarantine"
            content="Danh sách đang cách ly"
          />
          <Dropdown.Item
            as={Link}
            to="/facilities-completed"
            content="Danh sách kết thúc cách ly"
          />
          {isAdmin && (
            <Dropdown.Item
              as={Link}
              to="/facility-statistic"
              content="Thống kê (mockup)"
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown item simple text="Cách ly tại nhà">
        <Dropdown.Menu>
          <Dropdown.Item
            disabled
            as={Link}
            to="/home-waiting-list"
            content="Danh sách chờ cách ly"
          />
          <Dropdown.Item
            disabled
            as={Link}
            to="/home-in-quarantine"
            content="Danh sách đang cách ly"
          />
          <Dropdown.Item
            disabled
            as={Link}
            to="/home-completed"
            content="Danh sách kết thúc cách ly"
          />
          {isAdmin && (
            <Dropdown.Item
              as={Link}
              to="/facility-statistic"
              content="Thống kê (mockup)"
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown item simple text="Điều trị">
        <Dropdown.Menu>
          {isHcdcDtr ? (
            <>
              <Dropdown.Item
                as={Link}
                to="/tm-hospital"
                content="Bệnh viện tuyến trên"
              />
              <Dropdown.Item
                as={Link}
                to="/tm-facility"
                content="Cơ sở điều trị và điều phối"
              />
              <Dropdown.Item
                as={Link}
                to="/tm-waiting-for-treatment"
                content="Chờ điều trị"
              />
            </>
          ) : (
            <Dropdown.Item as={Link} to="/tm-employee" content="Cán bộ y tế" />
          )}
          <Dropdown.Item
            as={Link}
            to="/tm-waiting-to-take"
            content="Chờ tiếp nhận"
          />
          <Dropdown.Item as={Link} to="/tm-taken" content="Đã tiếp nhận" />
          <Dropdown.Item as={Link} to="/tm-transfer" content="Đã chuyển khu" />
          <Dropdown.Item
            as={Link}
            to="/tm-transited"
            content="Đã chuyển tuyến trên"
          />
          <Dropdown.Item as={Link} to="/tm-completed" content="Đã hoàn thành" />
          <Dropdown.Item
            as={Link}
            to="/tm-out-of-process"
            content="Ngoài quy trình"
          />
        </Dropdown.Menu>
      </Dropdown> */}
      <Dropdown item simple text="Quản lý f0">
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/total-infected-patient" content="Tổng F0" />
          <Dropdown.Item as={Link} to="/infectious-disease" content="F0 từ cơ sở y tế" />
          <Dropdown.Item as={Link} to="/infected-patient" content="F0 tự khai báo" />
        </Dropdown.Menu>
      </Dropdown>
      {/* <Menu.Item as={Link} to='/infected-patient' content="Quản lý f0" /> */}
      {/* <Menu.Item as={Link} to="/treatment" content="Điều trị" />
      <Menu.Item as={Link} to="/report" content="Báo cáo" /> */}
      {/* <Dropdown item simple text="Báo cáo">
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/report" content="Báo cáo tổng" />
        </Dropdown.Menu>
      </Dropdown> */}
    </>
  );
};

export default MenuItems;
