export default {
  TM_GET_FACILITY_INFO_REQUEST: 'TM_GET_FACILITY_INFO_REQUEST',
  TM_GET_FACILITY_INFO_SUCCESS: 'TM_GET_FACILITY_INFO_SUCCESS',
  TM_GET_FACILITY_INFO_FAILURE: 'TM_GET_FACILITY_INFO_FAILURE',

  TM_GET_ACCOUNT_INFO_REQUEST: 'TM_GET_ACCOUNT_INFO_REQUEST',
  TM_GET_ACCOUNT_INFO_SUCCESS: 'TM_GET_ACCOUNT_INFO_SUCCESS',
  TM_GET_ACCOUNT_INFO_FAILURE: 'TM_GET_ACCOUNT_INFO_FAILURE',

  GET_FACILITY_LIST_REQUEST: 'GET_FACILITY_LIST_REQUEST',
  GET_FACILITY_LIST_SUCCESS: 'GET_FACILITY_LIST_SUCCESS',
  GET_FACILITY_LIST_FAILURE: 'GET_FACILITY_LIST_FAILURE',

  GET_FACILITY_DETAIL_REQUEST: 'GET_FACILITY_DETAIL_REQUEST',
  GET_FACILITY_DETAIL_SUCCESS: 'GET_FACILITY_DETAIL_SUCCESS',
  GET_FACILITY_DETAIL_FAILURE: 'GET_FACILITY_DETAIL_FAILURE',

  CREATE_FACILITY_REQUEST: 'CREATE_FACILITY_REQUEST',
  CREATE_FACILITY_SUCCESS: 'CREATE_FACILITY_SUCCESS',
  CREATE_FACILITY_FAILURE: 'CREATE_FACILITY_FAILURE',

  UPDATE_FACILITY_REQUEST: 'UPDATE_FACILITY_REQUEST',
  UPDATE_FACILITY_SUCCESS: 'UPDATE_FACILITY_SUCCESS',
  UPDATE_FACILITY_FAILURE: 'UPDATE_FACILITY_FAILURE',

  DELETE_FACILITY_REQUEST: 'DELETE_FACILITY_REQUEST',
  DELETE_FACILITY_SUCCESS: 'DELETE_FACILITY_SUCCESS',
  DELETE_FACILITY_FAILURE: 'DELETE_FACILITY_FAILURE',

  GET_ALREADY_IN_WAITINGLIST_REQUEST: 'GET_ALREADY_IN_WAITINGLIST_REQUEST',
  GET_ALREADY_IN_WAITINGLIST_SUCCESS: 'GET_ALREADY_IN_WAITINGLIST_SUCCESS',
  GET_ALREADY_IN_WAITINGLIST_FAILURE: 'GET_ALREADY_IN_WAITINGLIST_FAILURE',

  GET_WAITINGLIST_BY_FACILITY_REQUEST: 'GET_WAITINGLIST_BY_FACILITY_REQUEST',
  GET_WAITINGLIST_BY_FACILITY_SUCCESS: 'GET_WAITINGLIST_BY_FACILITY_SUCCESS',
  GET_WAITINGLIST_BY_FACILITY_FAILURE: 'GET_WAITINGLIST_BY_FACILITY_FAILURE',

  WAITING_LIST_ADD_PROFILES_REQUEST: 'WAITING_LIST_ADD_PROFILES_REQUEST',
  WAITING_LIST_ADD_PROFILES_SUCCESS: 'WAITING_LIST_ADD_PROFILES_SUCCESS',
  WAITING_LIST_ADD_PROFILES_FAILURE: 'WAITING_LIST_ADD_PROFILES_FAILURE',

  WAITINGLIST_APPROVE_REQUEST: 'WAITINGLIST_APPROVE_REQUEST',
  WAITINGLIST_APPROVE_SUCCESS: 'WAITINGLIST_APPROVE_SUCCESS',
  WAITINGLIST_APPROVE_FAILURE: 'WAITINGLIST_APPROVE_FAILURE',

  GET_EXPECTED_QUARANTINE_DATE_REQUEST: 'GET_EXPECTED_QUARANTINE_DATE_REQUEST',
  GET_EXPECTED_QUARANTINE_DATE_SUCCESS: 'GET_EXPECTED_QUARANTINE_DATE_SUCCESS',
  GET_EXPECTED_QUARANTINE_DATE_FAILURE: 'GET_EXPECTED_QUARANTINE_DATE_FAILURE',

  UPDATE_EXPECTED_QUARANTINE_DATE_REQUEST:
    'UPDATE_EXPECTED_QUARANTINE_DATE_REQUEST',
  UPDATE_EXPECTED_QUARANTINE_DATE_SUCCESS:
    'UPDATE_EXPECTED_QUARANTINE_DATE_SUCCESS',
  UPDATE_EXPECTED_QUARANTINE_DATE_FAILURE:
    'UPDATE_EXPECTED_QUARANTINE_DATE_FAILURE',

  GET_QUARANTINE_LIST_BY_FACILITY_REQUEST:
    'GET_QUARANTINE_LIST_BY_FACILITY_REQUEST',
  GET_QUARANTINE_LIST_BY_FACILITY_SUCCESS:
    'GET_QUARANTINE_LIST_BY_FACILITY_SUCCESS',
  GET_QUARANTINE_LIST_BY_FACILITY_FAILURE:
    'GET_QUARANTINE_LIST_BY_FACILITY_FAILURE',

  TM_COMPLETE_REQUEST: 'TM_COMPLETE_REQUEST',
  TM_COMPLETE_SUCCESS: 'TM_COMPLETE_SUCCESS',
  TM_COMPLETE_FAILURE: 'TM_COMPLETE_FAILURE',

  TM_TRANSIT_REQUEST: 'TM_TRANSIT_REQUEST',
  TM_TRANSIT_SUCCESS: 'TM_TRANSIT_SUCCESS',
  TM_TRANSIT_FAILURE: 'TM_TRANSIT_FAILURE',

  TM_OUT_OF_PROCESS_REQUEST: 'TM_OUT_OF_PROCESS_REQUEST',
  TM_OUT_OF_PROCESS_SUCCESS: 'TM_OUT_OF_PROCESS_SUCCESS',
  TM_OUT_OF_PROCESS_FAILURE: 'TM_OUT_OF_PROCESS_FAILURE',

  TM_TRANSFER_REQUEST: 'TM_TRANSFER_REQUEST',
  TM_TRANSFER_SUCCESS: 'TM_TRANSFER_SUCCESS',
  TM_TRANSFER_FAILURE: 'TM_TRANSFER_FAILURE',

  GET_VISITS_REQUEST: 'GET_VISITS_REQUEST',
  GET_VISITS_SUCCESS: 'GET_VISITS_SUCCESS',
  GET_VISITS_FAILURE: 'GET_VISITS_FAILURE',

  CREATE_VISIT_REQUEST: 'CREATE_VISIT_REQUEST',
  CREATE_VISIT_SUCCESS: 'CREATE_VISIT_SUCCESS',
  CREATE_VISIT_FAILURE: 'CREATE_VISIT_FAILURE',

  TM_GET_COMPLETED_REQUEST: 'TM_GET_COMPLETED_REQUEST',
  TM_GET_COMPLETED_SUCCESS: 'TM_GET_COMPLETED_SUCCESS',
  TM_GET_COMPLETED_FAILURE: 'TM_GET_COMPLETED_FAILURE',

  TM_GET_TRANSITED_REQUEST: 'TM_GET_TRANSITED_REQUEST',
  TM_GET_TRANSITED_SUCCESS: 'TM_GET_TRANSITED_SUCCESS',
  TM_GET_TRANSITED_FAILURE: 'TM_GET_TRANSITED_FAILURE',

  TM_GET_OUT_OF_PROCESS_REQUEST: 'TM_GET_OUT_OF_PROCESS_REQUEST',
  TM_GET_OUT_OF_PROCESS_SUCCESS: 'TM_GET_OUT_OF_PROCESS_SUCCESS',
  TM_GET_OUT_OF_PROCESS_FAILURE: 'TM_GET_OUT_OF_PROCESS_FAILURE',

  TM_GET_HOSPITALS_REQUEST: 'TM_GET_HOSPITALS_REQUEST',
  TM_GET_HOSPITALS_SUCCESS: 'TM_GET_HOSPITALS_SUCCESS',
  TM_GET_HOSPITALS_FAILURE: 'TM_GET_HOSPITALS_FAILURE',

  TM_CREATE_HOSPITAL_REQUEST: 'TM_CREATE_HOSPITAL_REQUEST',
  TM_CREATE_HOSPITAL_SUCCESS: 'TM_CREATE_HOSPITAL_SUCCESS',
  TM_CREATE_HOSPITAL_FAILURE: 'TM_CREATE_HOSPITAL_FAILURE',

  TM_UPDATE_HOSPITAL_REQUEST: 'TM_UPDATE_HOSPITAL_REQUEST',
  TM_UPDATE_HOSPITAL_SUCCESS: 'TM_UPDATE_HOSPITAL_SUCCESS',
  TM_UPDATE_HOSPITAL_FAILURE: 'TM_UPDATE_HOSPITAL_FAILURE',

  TM_DELETE_HOSPITAL_REQUEST: 'TM_DELETE_HOSPITAL_REQUEST',
  TM_DELETE_HOSPITAL_SUCCESS: 'TM_DELETE_HOSPITAL_SUCCESS',
  TM_DELETE_HOSPITAL_FAILURE: 'TM_DELETE_HOSPITAL_FAILURE',

  TM_GET_HOSPITALS_BY_FACILITY_REQUEST: 'TM_GET_HOSPITALS_BY_FACILITY_REQUEST',
  TM_GET_HOSPITALS_BY_FACILITY_SUCCESS: 'TM_GET_HOSPITALS_BY_FACILITY_SUCCESS',
  TM_GET_HOSPITALS_BY_FACILITY_FAILURE: 'TM_GET_HOSPITALS_BY_FACILITY_FAILURE',

  TM_ADD_HOSPITALS_TO_FACILITY_REQUEST: 'TM_ADD_HOSPITALS_TO_FACILITY_REQUEST',
  TM_ADD_HOSPITALS_TO_FACILITY_SUCCESS: 'TM_ADD_HOSPITALS_TO_FACILITY_SUCCESS',
  TM_ADD_HOSPITALS_TO_FACILITY_FAILURE: 'TM_ADD_HOSPITALS_TO_FACILITY_FAILURE',

  TM_REMOVE_HOSPITALS_TO_FACILITY_REQUEST:
    'TM_REMOVE_HOSPITALS_TO_FACILITY_REQUEST',
  TM_REMOVE_HOSPITALS_TO_FACILITY_SUCCESS:
    'TM_REMOVE_HOSPITALS_TO_FACILITY_SUCCESS',
  TM_REMOVE_HOSPITALS_TO_FACILITY_FAILURE:
    'TM_REMOVE_HOSPITALS_TO_FACILITY_FAILURE',

  GET_EMPLOYEE_LIST_REQUEST: 'GET_EMPLOYEE_LIST_REQUEST',
  GET_EMPLOYEE_LIST_SUCCESS: 'GET_EMPLOYEE_LIST_SUCCESS',
  GET_EMPLOYEE_LIST_FAILURE: 'GET_EMPLOYEE_LIST_FAILURE',

  GET_EMPLOYEE_BY_TOKEN_REQUEST: 'GET_EMPLOYEE_BY_TOKEN_REQUEST',
  GET_EMPLOYEE_BY_TOKEN_SUCCESS: 'GET_EMPLOYEE_BY_TOKEN_SUCCESS',
  GET_EMPLOYEE_BY_TOKEN_FAILURE: 'GET_EMPLOYEE_BY_TOKEN_FAILURE',

  CREATE_EMPLOYEE_REQUEST: 'CREATE_EMPLOYEE_REQUEST',
  CREATE_EMPLOYEE_SUCCESS: 'CREATE_EMPLOYEE_SUCCESS',
  CREATE_EMPLOYEE_FAILURE: 'CREATE_EMPLOYEE_FAILURE',

  UPDATE_EMPLOYEE_BY_ID_REQUEST: 'UPDATE_EMPLOYEE_BY_ID_REQUEST',
  UPDATE_EMPLOYEE_BY_ID_SUCCESS: 'UPDATE_EMPLOYEE_BY_ID_SUCCESS',
  UPDATE_EMPLOYEE_BY_ID_FAILURE: 'UPDATE_EMPLOYEE_BY_ID_FAILURE',

  UPDATE_EMPLOYEE_BY_TOKEN_REQUEST: 'UPDATE_EMPLOYEE_BY_TOKEN_REQUEST',
  UPDATE_EMPLOYEE_BY_TOKEN_SUCCESS: 'UPDATE_EMPLOYEE_BY_TOKEN_SUCCESS',
  UPDATE_EMPLOYEE_BY_TOKEN_FAILURE: 'UPDATE_EMPLOYEE_BY_TOKEN_FAILURE',

  DELETE_EMPLOYEE_REQUEST: 'DELETE_EMPLOYEE_REQUEST',
  DELETE_EMPLOYEE_SUCCESS: 'DELETE_EMPLOYEE_SUCCESS',
  DELETE_EMPLOYEE_FAILURE: 'DELETE_EMPLOYEE_FAILURE',

  GET_PROFILE_LIST_REQUEST: 'GET_PROFILE_LIST_REQUEST',
  GET_PROFILE_LIST_SUCCESS: 'GET_PROFILE_LIST_SUCCESS',
  GET_PROFILE_LIST_FAILURE: 'GET_PROFILE_LIST_FAILURE',

  CREATE_PROFILE_LIST_REQUEST: 'CREATE_PROFILE_LIST_REQUEST',
  CREATE_PROFILE_LIST_SUCCESS: 'CREATE_PROFILE_LIST_SUCCESS',
  CREATE_PROFILE_LIST_FAILURE: 'CREATE_PROFILE_LIST_FAILURE',

  TM_CREATE_NEW_PROFILE_REQUEST: 'TM_CREATE_NEW_PROFILE_REQUEST',
  TM_CREATE_NEW_PROFILE_SUCCESS: 'TM_CREATE_NEW_PROFILE_SUCCESS',
  TM_CREATE_NEW_PROFILE_FAILURE: 'TM_CREATE_NEW_PROFILE_FAILURE',

  GET_EMPLOYEE_TYPES_REQUEST: 'GET_EMPLOYEE_TYPES_REQUEST',
  GET_EMPLOYEE_TYPES_SUCCESS: 'GET_EMPLOYEE_TYPES_SUCCESS',
  GET_EMPLOYEE_TYPES_FAILURE: 'GET_EMPLOYEE_TYPES_FAILURE',

  TM_GET_TRANSFER_REQUEST: 'TM_GET_TRANSFER_REQUEST',
  TM_GET_TRANSFER_SUCCESS: 'TM_GET_TRANSFER_SUCCESS',
  TM_GET_TRANSFER_FAILURE: 'TM_GET_TRANSFER_FAILURE',

  TM_APPROVE_TRANSFER_REQUEST: 'TM_APPROVE_TRANSFER_REQUEST',
  TM_APPROVE_TRANSFER_SUCCESS: 'TM_APPROVE_TRANSFER_SUCCESS',
  TM_APPROVE_TRANSFER_FAILURE: 'TM_APPROVE_TRANSFER_FAILURE',

  TM_UNDO_REQUEST: 'TM_UNDO_REQUEST',
  TM_UNDO_SUCCESS: 'TM_UNDO_SUCCESS',
  TM_UNDO_FAILURE: 'TM_UNDO_FAILURE',

  UPDATE_VISIT_REQUEST: 'UPDATE_VISIT_REQUEST',
  UPDATE_VISIT_SUCCESS: 'UPDATE_VISIT_SUCCESS',
  UPDATE_VISIT_FAILURE: 'UPDATE_VISIT_FAILURE',

  COMPLETE_VISIT_REQUEST: 'COMPLETE_VISIT_REQUEST',
  COMPLETE_VISIT_SUCCESS: 'COMPLETE_VISIT_SUCCESS',
  COMPLETE_VISIT_FAILURE: 'COMPLETE_VISIT_FAILURE',
};
