import React from 'react';
import BeingInHomeTable from 'quarantine-facilities/components/quarantine-at-home/BeingInHomeTable';

const InHomePage = (props) => {
  return (
    <div>
      <BeingInHomeTable />
    </div>
  );
};

export default InHomePage;
