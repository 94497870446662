import CompletedSubjectTable from 'quarantine/components/completed/CompletedSubjectTable';
import React from 'react';

const CompletedSubjectPage = (props) => {
  return (
    <div>
      <CompletedSubjectTable />
    </div>
  );
};

export default CompletedSubjectPage;
