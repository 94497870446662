// happening
export const GET_ALL_HAPPENINGS_REQUEST = 'GET_ALL_HAPPENINGS_REQUEST';
export const GET_ALL_HAPPENINGS_SUCCESS = 'GET_ALL_HAPPENINGS_SUCCESS';
export const GET_ALL_HAPPENINGS_FAILURE = 'GET_ALL_HAPPENINGS_FAILURE';

export const GET_HAPPENINGS_REQUEST = 'GET_HAPPENINGS_REQUEST';
export const GET_HAPPENINGS_SUCCESS = 'GET_HAPPENINGS_SUCCESS';
export const GET_HAPPENINGS_FAILURE = 'GET_HAPPENINGS_FAILURE';

export const CREATE_HAPPENING_REQUEST = 'CREATE_HAPPENING_REQUEST';
export const CREATE_HAPPENING_SUCCESS = 'CREATE_HAPPENING_SUCCESS';
export const CREATE_HAPPENING_FAILURE = 'CREATE_HAPPENING_FAILURE';

export const UPDATE_HAPPENING_REQUEST = 'UPDATE_HAPPENING_REQUEST';
export const UPDATE_HAPPENING_SUCCESS = 'UPDATE_HAPPENING_SUCCESS';
export const UPDATE_HAPPENING_FAILURE = 'UPDATE_HAPPENING_FAILURE';

export const DELETE_HAPPENING_REQUEST = 'DELETE_HAPPENING_REQUEST';
export const DELETE_HAPPENING_SUCCESS = 'DELETE_HAPPENING_SUCCESS';
export const DELETE_HAPPENING_FAILURE = 'DELETE_HAPPENING_FAILURE';

export default {
  // subject
  GET_SUBJECT_REQUEST: 'GET_SUBJECT_REQUEST',
  GET_SUBJECT_SUCCESS: 'GET_SUBJECT_SUCCESS',
  GET_SUBJECT_FAILURE: 'GET_SUBJECT_FAILURE',

  GET_SUBJECTS_REQUEST: 'GET_SUBJECTS_REQUEST',
  GET_SUBJECTS_SUCCESS: 'GET_SUBJECTS_SUCCESS',
  GET_SUBJECTS_FAILURE: 'GET_SUBJECTS_FAILURE',

  CREATE_SUBJECT_REQUEST: 'CREATE_SUBJECT_REQUEST',
  CREATE_SUBJECT_SUCCESS: 'CREATE_SUBJECT_SUCCESS',
  CREATE_SUBJECT_FAILURE: 'CREATE_SUBJECT_FAILURE',

  UPDATE_SUBJECT_REQUEST: 'UPDATE_SUBJECT_REQUEST',
  UPDATE_SUBJECT_SUCCESS: 'UPDATE_SUBJECT_SUCCESS',
  UPDATE_SUBJECT_FAILURE: 'UPDATE_SUBJECT_FAILURE',

  DELETE_SUBJECT_REQUEST: 'DELETE_SUBJECT_REQUEST',
  DELETE_SUBJECT_SUCCESS: 'DELETE_SUBJECT_SUCCESS',
  DELETE_SUBJECT_FAILURE: 'DELETE_SUBJECT_FAILURE',

  VERIFY_SUBJECT_REQUEST: 'VERIFY_SUBJECT_REQUEST',
  VERIFY_SUBJECT_SUCCESS: 'VERIFY_SUBJECT_SUCCESS',
  VERIFY_SUBJECT_FAILURE: 'VERIFY_SUBJECT_FAILURE',

  GET_CONTACTS_REQUEST: 'GET_CONTACTS_REQUEST',
  GET_CONTACTS_SUCCESS: 'GET_CONTACTS_SUCCESS',
  GET_CONTACTS_FAILURE: 'GET_CONTACTS_FAILURE',

  CREATE_CONTACT_REQUEST: 'CREATE_CONTACT_REQUEST',
  CREATE_CONTACT_SUCCESS: 'CREATE_CONTACT_SUCCESS',
  CREATE_CONTACT_FAILURE: 'CREATE_CONTACT_FAILURE',

  UPDATE_CONTACT_REQUEST: 'UPDATE_CONTACT_REQUEST',
  UPDATE_CONTACT_SUCCESS: 'UPDATE_CONTACT_SUCCESS',
  UPDATE_CONTACT_FAILURE: 'UPDATE_CONTACT_FAILURE',

  DELETE_CONTACT_REQUEST: 'DELETE_CONTACT_REQUEST',
  DELETE_CONTACT_SUCCESS: 'DELETE_CONTACT_SUCCESS',
  DELETE_CONTACT_FAILURE: 'DELETE_CONTACT_FAILURE',

  GET_SYMPTOMS_REQUEST: 'GET_SYMPTOMS_REQUEST',
  GET_SYMPTOMS_SUCCESS: 'GET_SYMPTOMS_SUCCESS',
  GET_SYMPTOMS_FAILURE: 'GET_SYMPTOMS_FAILURE',

  CREATE_SYMPTOM_REQUEST: 'CREATE_SYMPTOM_REQUEST',
  CREATE_SYMPTOM_SUCCESS: 'CREATE_SYMPTOM_SUCCESS',
  CREATE_SYMPTOM_FAILURE: 'CREATE_SYMPTOM_FAILURE',

  GET_UNDERLYING_DISEASES_REQUEST: 'GET_UNDERLYING_DISEASES_REQUEST',
  GET_UNDERLYING_DISEASES_SUCCESS: 'GET_UNDERLYING_DISEASES_SUCCESS',
  GET_UNDERLYING_DISEASES_FAILURE: 'GET_UNDERLYING_DISEASES_FAILURE',

  CREATE_UNDERLYING_DISEASE_REQUEST: 'CREATE_UNDERLYING_DISEASE_REQUEST',
  CREATE_UNDERLYING_DISEASE_SUCCESS: 'CREATE_UNDERLYING_DISEASE_SUCCESS',
  CREATE_UNDERLYING_DISEASE_FAILURE: 'CREATE_UNDERLYING_DISEASE_FAILURE',

  PROCESS_SUBJECT_REQUEST: 'PROCESS_SUBJECT_REQUEST',
  PROCESS_SUBJECT_SUCCESS: 'PROCESS_SUBJECT_SUCCESS',
  PROCESS_SUBJECT_FAILURE: 'PROCESS_SUBJECT_FAILURE',

  GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
  GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
  GET_REPORT_FAILURE: 'GET_REPORT_FAILURE',

  GET_SUMMARY_REPORTS_REQUEST: 'GET_SUMMARY_REPORTS_REQUEST',
  GET_SUMMARY_REPORTS_SUCCESS: 'GET_SUMMARY_REPORTS_SUCCESS',
  GET_SUMMARY_REPORTS_FAILURE: 'GET_SUMMARY_REPORTS_FAILURE',

  UPLOAD_SUBJECT_FILE_REQUEST: 'UPLOAD_SUBJECT_FILE_REQUEST',
  UPLOAD_SUBJECT_FILE_SUCCESS: 'UPLOAD_SUBJECT_FILE_SUCCESS',
  UPLOAD_SUBJECT_FILE_FAILURE: 'UPLOAD_SUBJECT_FILE_FAILURE',
  SET_UPLOAD_SUBJECT_FILE_PROGRESS: 'SET_UPLOAD_SUBJECT_FILE_PROGRESS',

  GET_SUBJECT_RELATED_REQUEST: 'GET_SUBJECT_RELATED_REQUEST',
  GET_SUBJECT_RELATED_SUCCESS: 'GET_SUBJECT_RELATED_SUCCESS',
  GET_SUBJECT_RELATED_FAILURE: 'GET_SUBJECT_RELATED_FAILURE',

  SEARCH_SUBJECT_REQUEST: 'SEARCH_SUBJECT_REQUEST',
  SEARCH_SUBJECT_SUCCESS: 'SEARCH_SUBJECT_SUCCESS',
  SEARCH_SUBJECT_FAILURE: 'SEARCH_SUBJECT_FAILURE',

  EXPORT_SUBJECTS_REQUEST: 'EXPORT_SUBJECTS_REQUEST',
  EXPORT_SUBJECTS_SUCCESS: 'EXPORT_SUBJECTS_SUCCESS',
  EXPORT_SUBJECTS_FAILURE: 'EXPORT_SUBJECTS_FAILURE',

  SELECT_F0_ON_CREATING_PROFILE: 'SELECT_F0_ON_CREATING_PROFILE',
  RESET_SUBJECT_RELATED: 'RESET_SUBJECT_RELATED',
};
